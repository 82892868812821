import { userService } from '../_services';
import router from '../router';

interface User {
  email: string;
  password: string;
  captcha:string;
  key:string
  sso_connection?:boolean
}

interface State {
  status: {
    loggingIn?: boolean;
    loggedIn?: boolean;
    registering?: boolean;
  };
  user: User | null;
}

const user: User | null = JSON.parse(localStorage.getItem('user') || '{}');
const state: State = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

const actions = {
  async loginNoRecaptcha({ dispatch, commit }: any, { email, password}: User) {
    commit('loginRequest', { email });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await userService.loginNoRecaptcha(email, password)
       .then(
            (user: User) => {
              commit('loginSuccess', user);
            },
            (error: any) => {
              commit('loginFailure', error);
              dispatch('alert/error', error, { root: true });
              throw new Error('Email and Password do not match our records');
            }
        );
  },
  async login({ dispatch, commit }: any, { email, password ,captcha,key,sso_connection}: User) {
    commit('loginRequest', { email });

    await userService.login(email, password,captcha,key,sso_connection)
        .then(
            (user: User) => {
              commit('loginSuccess', user);
            },
            (error: any) => {
              commit('loginFailure', error);
              dispatch('alert/error', error, { root: true });
              if (error == 406){
                throw new Error('Captcha error');
              }else{
                throw new Error('Email and Password do not match our records');
              }

            }
        );

  },
  async logout({ commit }: any) {
    await userService.logout();
    commit('logout');
  },
  async register({ dispatch, commit }: any, user: BodyInit) {
    commit('registerRequest', user);

    await userService.register(user)
      .then(
        (user: User) => {
          commit('registerSuccess', user);
          router.push('/dashboard/?onboarding=on');
        },
        (error: any) => {
          commit('registerFailure', error);
          dispatch('alert/error', error, { root: true });
          throw new Error('Cannot register');
        }
      );
  },
  updateUserObject({ commit }: any) {
    commit('updateUserObject', JSON.parse(localStorage.getItem('user') || '{}'));
  }
};

const mutations = {
  loginRequest(state: State, user: User) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state: State, user: User) {
    state.status = { loggedIn: true };
    state.user = user;
    router.push("/home");
  },
  loginFailure(state: State) {
    state.status = {};
    state.user = null;
  },
  logout(state: State) {
    state.status = {};
    state.user = null;
    router.push("/login");
  },
  registerRequest(state: State, user: User) {
    state.status = { registering: true };
    state.user = user;
  },
  registerSuccess(state: State, user: User) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  registerFailure(state: State) {
    state.status = { loggedIn: false };
    state.user = null;
  },
  updateUserObject(state: State, user: User) {
    state.user = user;
  }
};

export const authentication = {
  namespaced: true,
  state,
  actions,
  mutations
};