<template>
  <div class="footer-container justify-content-between sticky">
    <img class="footer-logo" src="../assets/images/Logo_Bleu.png" alt=""/>
    <div class="footer-items animate-flicker">
      {{ internet ? '' : $t('internet_status.off') }}
    </div>
    <div class="footer-items">
      <lbrx-language-select-popup v-if="showLanguageSelect" @close="showLanguageSelect = false"
                                  v-model="showLanguageSelect"></lbrx-language-select-popup>
      <lbrx-technicial-assistance-popup v-if="showAssistance" @close="showAssistance = false"
                                        v-model="showAssistance"></lbrx-technicial-assistance-popup>
      <div class="copyright main-copyright">
        Liberrex.com © Copyright {{ new Date().getFullYear() }}
      </div>
      <div class="footer-buttons main-footer-buttons">
        <lbrx-footer-button id="languageButton" class="footer-button" @clicked="showLanguageSelect = true"
                            icon="language-icon"
                            :label="language" color="#8A8A8A" background="white" height="4.2vmin" width="20vmin"
                            font-size="0.6" border="#F5F5F5"></lbrx-footer-button>
        <lbrx-footer-button id="assistanceButton" icon="assistance-icon" @clicked="showAssistance = true"
                            :label="$t('Teller.NeedHelp')"
                            color="#8A8A8A"
                            background="white" height="4.2vmin" width="20vmin" font-size="0.6"
                            border="#F5F5F5"></lbrx-footer-button>
      </div>

    </div>
  </div>
</template>
<script>
import LbrxFooterButton from "@/components/LbrxFooterButton.vue";
import LbrxLanguageSelectPopup from "@/components/LbrxLanguageSelectPopup.vue";
import LbrxTechnicialAssistancePopup from "@/components/LbrxTechnicialAssistancePopup.vue";


export default {
  name: 'TheFooter',
  computed: {},
  components: {LbrxTechnicialAssistancePopup, LbrxLanguageSelectPopup, LbrxFooterButton},
  props: {internet: {type: Boolean, required: true}},
  data() {
    return {
      showLanguageSelect: false,
      showAssistance: false,
      language: '--'
    }
  },
  mounted() {
    let lang = localStorage.getItem('Language')
    if (lang === 'fr') {
      this.language = 'Français'
    }
    if (lang === 'ar') {
      this.language = 'العربية'
    }
    if (lang === 'en') {
      this.language = 'English'
    }

  }
}
</script>
<style scoped>

@media (max-width: 767px) {

}

@media (max-width: 680px) {
  .footer-container {
    z-index: 1;
    min-height: 100% !important;
  }

  .footer-button {
    margin-bottom: 1vmin;
  }

  .main-footer-buttons {
    display: flex !important;
    flex-direction: column;
    margin-left: 7vmin;
    margin-top: 1vmin !important;
    margin-right: 5vmin;
  }

  .main-copyright {
    margin-top: 1.5vmin;
  }

  .footer-logo {
    margin-top: 1.5vmin !important;
    margin-left: 4vmin !important;
  }
}

.main-copyright {
  color: #8A8A8A !important;
}

.main-footer-buttons {
  margin-top: -1vmin;
  margin-right: 1vmin;
}

.footer-logo {
  height: 4vmin !important;
  margin-left: 3vmin;
}

.footer-items {
  display: flex;
  margin: 3px;
  margin-right: 8px !important;
}

.footer-container {
  z-index: 1;
  padding-bottom: 2vmin;
  padding-top: 3vh;
  width: 100%;
  background: white !important;
  display: flex;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  color: red !important;
  font-weight: bold !important;
}
</style>