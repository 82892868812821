<template>
  <div v-if="modelValue.item !== null" class="ticket-class align-items-center">
    <div :class="{'right-class': $i18n.locale === 'ar'}" class="row ticket-title">
      <div class="ticket-element title main-title">{{ $t('Teller.TicketNumber') }}</div>
      <div class="ticket-element value ticket-title-value">
        <img class="ticket-image bigImage" src="../assets/icons/ticket-icon.svg" alt=""/>
        <span id="publicIdentifier" class="ticket-number">{{ modelValue.item.public_identifier.replace(' ', '') }}</span>
      </div>
    </div>
    <div class="row">
      <div :class="{'right-class': $i18n.locale === 'ar'}" class="flex-fill ticket-container" >
        <div class="ticket-element title">{{ $t('Teller.WaitingTime') }}</div>
        <div class="ticket-element value">
          <img class="ticket-image mx-1" src="../assets/icons/044-45minutes.svg" alt=""/>
          <div v-if="modelValue.waitingTime !== 0" class="justify-content-between">
            <span id="waitingHours" v-if="waitingHours">{{ waitingHours }}
              <span class="time-tag" :class="{'time-tag-rtl': $i18n.locale === 'ar'}">
                {{ $t('Teller.Hr') }}</span>
            </span>
            <span v-else>00<span class="time-tag" :class="{'time-tag-rtl': $i18n.locale === 'ar'}">
                {{ $t('Teller.Hr') }}</span>
            </span>
            <span id="waitingMinutes"
                v-if="waitingMinutes">{{ waitingMinutes }}<span class="time-tag"
                                                                :class="{'time-tag-rtl': $i18n.locale === 'ar'}">{{
                $t('Teller.Min')
              }}</span>
            </span>

            <span v-else>00<span class="time-tag" :class="{'time-tag-rtl': $i18n.locale === 'ar'}">{{
                $t('Teller.Min')
              }}</span>
            </span>

          </div>
          <div v-else class="justify-content-between">
            <span>
              00<span class="time-tag">{{ $t('Teller.Hr') }}</span>
            </span>
            <span>
              00<span
                class="time-tag">{{ $t('Teller.Min') }}</span>
            </span>
            <span>
              00<span class="time-tag">{{ $t('Teller.Sec') }}</span>
            </span>

          </div>

        </div>
      </div>
      <div :class="{'right-class': $i18n.locale === 'ar'}" class="flex-fill ticket-container">
        <div class="ticket-element title">{{ $t('Teller.RemainingTime') }}</div>
        <div class="ticket-element value" :class="this.modelValue.item.estimatedTimeToSpend<60?' animate-flicker':''">
          <img v-if="this.modelValue.item.estimatedTimeToSpend<60" class="ticket-image"
               src="../assets/icons/stopwatch-icon-red.svg" alt=""/>
          <img v-else class="ticket-image mx-1" src="../assets/icons/stopwatch-icon.svg" alt=""/>
          <div v-if="modelValue.item.estimatedTimeToSpend!==0" class="justify-content-between">
            <span id="remaininghours" v-if="remainingHours">
              {{ remainingHours }}
              <span class="time-tag" :class="{'time-tag-rtl': $i18n.locale === 'ar'}">
                {{ $t('Teller.Hr') }}</span>
            </span>
            <span id="remainingMinutes" v-if="remainingMinutes">{{ remainingMinutes }}<span class="time-tag"
                                                                      :class="{'time-tag-rtl': $i18n.locale === 'ar'}">{{
                $t('Teller.Min')
              }}</span>
            </span>
            <span v-else>00<span class="time-tag" :class="{'time-tag-rtl': $i18n.locale === 'ar'}">{{
                $t('Teller.Min')
              }}</span>
            </span><span id="remainingSeconds"
              v-if="remainingSeconds">{{ remainingSeconds }}<span class="time-tag"
                                                                  :class="{'time-tag-rtl': $i18n.locale === 'ar'}">{{
              $t('Teller.Sec')
            }}</span></span>
            <span v-else>00<span class="time-tag" :class="{'time-tag-rtl': $i18n.locale === 'ar'}">
                {{ $t('Teller.Sec') }}</span>
            </span>
          </div>
          <div v-else class="justify-content-between">
            <span>
              00<span
                class="time-tag">{{ $t('Teller.Min') }}</span>
            </span>
            <span>
              00<span class="time-tag">{{ $t('Teller.Sec') }}</span>
            </span>

          </div>
        </div>
      </div>
    </div>
    <div class="row-cols-md-auto console-buttons-container">
      <img class="button-image" id="openNotes" src="../assets/icons/menu-button-icon.svg" alt="" @click="this.$emit('Note')"/>
      <img class="button-image" id="openRedirect" src="../assets/icons/arrow-button-icon.svg" alt="" @click="Redirect()"/>
      <img class="button-image" id="recalTicket" src="../assets/icons/volume-up-button-icon.svg" @click="Recall()" alt=""/>
    </div>
  </div>
  <div class="bg-white no-data-container" v-else>
    <div class="d-flex statistics-image-container">
      <img src="../assets/images/no-ticket-data.png"/>
    </div>
    <div class="no-data-label">{{ $t('Teller.No_ticket') }}</div>
  </div>
</template>
<script>
import moment from "moment/moment";

export default {
  name: 'LbrxTicketVertical',
  props: {
    modelValue: Object,
    counter_config: Object
  },
  computed: {
    waitingHours() {
      return this.getTimeValue(this.modelValue.waitingTime, "HH");
    },
    waitingMinutes() {
      return this.getTimeValue(this.modelValue.waitingTime, "mm");
    },
    waitingSeconds() {
      return this.getTimeValue(this.modelValue.waitingTime, "ss");
    },
    remainingHours() {
      return this.getTimeValue(this.modelValue.item.estimatedTimeToSpend, "HH");
    },
    remainingMinutes() {
      return this.getTimeValue(this.modelValue.item.estimatedTimeToSpend, "mm");
    },
    remainingSeconds() {
      return this.getTimeValue(this.modelValue.item.estimatedTimeToSpend, "ss");
    },
    fixFontSize() {
      if (this.modelValue.item !== null) {
        if (this.modelValue.item.public_identifier.length > 3) {
          return '5vmin'
        }
        return '7vmin'
      }
      return '7vmin'
    }
  },
  methods: {
    getTimeValue(time, format) {
      const t = moment.utc(time * 1000).format(format);
      if (t !== "00" && !isNaN(t)) {
         return t;
      }
      return null;
    },
    Recall() {
      this.$emit('Re-callTicket');
    },
    Redirect() {
      this.$emit('Redirect-ticket')
    }
  },
  mounted() {
    setInterval(() => {
      if (this.modelValue.item !== null) {
        if (this.modelValue.item.status === 'inservice' && this.modelValue.item.estimatedTimeToSpend > 0) {
          // eslint-disable-next-line vue/no-mutating-props
          this.modelValue.item.estimatedTimeToSpend--
        }
      }
    }, 1000)
  }
}
</script>
<style scoped>

@media (max-width: 767px) {
  .ticket-class {
    padding-bottom: 1vh !important;
  }
}
@media (max-width: 680px) {
  .no-data-label{
    text-align: unset!important;
  }

  .no-data-container {
    min-width: 100% !important;
    min-height: unset !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: flex;
    justify-content: center;
  }

  .statistics-image-container img {
    height: 14vmin;
  }

  .statistics-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1vmin !important;
  }

  .no-data-label {
    width: 100% !important;
    font-size: 5vmin !important;
    margin-top: 5vmin !important;
    margin-left: -20vmin !important;
  }

  .title {
    width: 34vmin !important;
  }

  .ticket-container {
    height: 35% !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .ticket-class {
    padding: 31px;
    margin-top: 3vh;
    display: flex !important;
    flex-direction: row !important;
    height: fit-content !important;
  }

  .button-image {
    margin-bottom: 1vmin;
    height: 8.5vmin !important;
  }

  .ticket-title .ticket-element {
    justify-content: left !important;
    margin-left: 2vmin !important;
    font-size: 2.4vmin;
    line-height: 2vmin;
    margin-bottom: 4vmin;
  }

  .ticket-title {
    margin-right: 3vmin !important;
    justify-content: left !important;
  }

  .time-container .ticket-element {
    justify-content: left !important;
  }

  .ticket-number {
    font-size: v-bind(fixFontSize) !important;
  }

  .bigImage {
    height: 8vmin !important;
  }

  .time-tag {
    font-size: 3vmin !important;
  }

  .value {
    margin-top: 2vmin !important;
    font-size: 5vmin !important;
    line-height: 4vmin !important;
    margin-bottom: 3vmin !important;
  }

  .console-buttons-container {
    width: min-content !important;
  }

  .ticket-element {
    justify-content: unset !important;
  }
}

.button-image {
  height: 5.2vmin;
  margin-left: 1vmin;
  margin-right: 1vmin;
  cursor: pointer;
}

.ticket-container {
  height: 28%;
  margin-bottom: 2vmin;
}

.ticket-title-value {
  margin-top: -0.5vmin !important;
}

.ticket-number {
  font-size: v-bind(fixFontSize) !important;
  line-height: 10vmin !important;
}

.ticket-image {
  margin-top: 0.4vmin;
  height: 2.7vmin;
  margin-right: 0.7vmin;
}

.time-tag {
  margin-right: 0.5vmin;
  font-size: 18px;
  line-height: 2vmin;
}

.time-tag-rtl {
  margin-left: 1vmin !important;
  margin-right: 0.2vmin !important;
}

.value {

  font-family: 'Exo 2', serif;
  font-style: normal;
  margin-bottom: 2vmin;
  margin-top: 1vmin;
  font-weight: 400;
  font-size: 3vmin;
  letter-spacing: -0.15vmin;
  line-height: 3.5vmin;

  /* Primary */

  color: #008FCA;
}

.title {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.ticket-element {
  display: flex;
  justify-content: center;
}

.ticket-class {
  padding: 20px;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bigImage {
  margin-top: 2vmin;
  margin-right: 1.7vmin;
  height: 5.5vmin;
}

.no-data-container {
  width: 100%;
  padding-top: 40%;
  height: 100%;
  background: white;
}

.statistics-image-container img {
  height: 14vmin;
}

.statistics-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.no-data-label {
  width: 100%;
  text-align: center;
  margin-top: 1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.8vmin;
  line-height: 4vmin;

  /* Gray Light */

  color: #E3E3E3;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  color: red !important;
  font-weight: bold !important;
}
</style>