<template>
  <div @click="checkThis" v-if="item" class="radio-button-container"
       :class="{'checked': item.selected,'disabled': item.disabled}">
    <div class="button-container">
      <div class="radio-button my-1 mx-2"></div>
    </div>
    <div class="button-label my-auto">
      <div class="button-number" :style="{'margin-right': $i18n.locale !== 'ar' ? '-5vmin' : ''}" >
        <img v-if="item.selected && item.number" class="button-icon" src="../assets/icons/radio-button-white-icon.svg"
             alt="">
        <img v-if="!item.selected && item.number" class="button-icon" src="../assets/icons/radio-button-grey-icon.svg"
             alt="">
        <div>{{ item.number }}</div>
      </div>
      <div>{{ this.item.title || item.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LbrxRadioButton',
  props: {
    item: Object
  },
  emits: ['update:modelValue'],
  methods: {
    checkThis() {
      this.$emit('select');
    }
  }
}
</script>
<style scoped>
.button-container {
  width: 5vmin !important;
}

.radio-button {
  height: 2.2vmin;
  width: 2.3vmin;
  border-radius: 120px;
  border: 1px solid #008FCA;
  margin-top: 1.3vmin;
  margin-right: 5vmin;
}

.checked .radio-button {
  background: #00CC6A;
  border: 1px solid white;
}

.checked .button-label {
  color: white !important;
}

.checked {
  cursor: default !important;
  background: #008FCA !important;
  /* Success */

  border-bottom: 2px solid #00CC6A !important;

}

.radio-button-container {
  cursor: pointer;
  display: flex;
  /* #E3E3E3 */
  background: rgba(227, 227, 227, 0.3);
  /* Primary */
  width: 100%;
  margin-bottom: 0.2vmin;
  margin-top: 0.2vmin;
  height: 7vmin;
  border-bottom: 2px solid #008FCA;
  padding: 2vmin;
}

.radio-button-container:hover {
  background: rgba(227, 227, 227, 0.7);
}

.button-label {
  display: flex;
  width: 100%;
  margin-top: 1vmin;
  font-size: 3vmin;
  line-height: 3vmin;
  color: #008FCA;
}

.button-number {
  display: flex;
  width: 7.5vmin;
  margin-right: -2vmin;
}

.button-icon {
  height: 3.5vmin;
}

.disabled {
  display: none;
}
</style>