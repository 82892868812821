<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box" :class="{'right-class': $i18n.locale === 'ar'}">
        <div class="container">
          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon" :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}" src="../assets/icons/web-browser-icon.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('Teller.confirm_title') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('Teller.confirm_details') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>
        </div>

        <div class="confirm-popup-body">
          <div class="m-auto">
            <div>
              <img  src="../assets/icons/info-grey.svg" width="80">
            </div>

            {{ $t('Teller.close_ticket') }}
          </div>
        </div>
        <div class="popup-buttons">
          <lbrx-button id="confirmAction" v-model="sending" icon="tick-mark-icon" :base-button="true" @clicked="triggerPopupAction()"
                       :label="$t('Teller.ConfirmButton')"
                       color="#FFFFFF" background="#00CC6A" height="9vmin" width="93%"
                       font-size="1.1"></lbrx-button>
          <lbrx-button id="cancelAction" v-model="close" icon="delete-red-icon" :base-button="true" @clicked="closeThis()" :label="$t('Teller.Close')"
                       color="#FF5757" border="1px solid #FF5757" background="white" height="9vmin"
                       width="93%" font-size="1.1"></lbrx-button>

        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";

export default {
  name: 'LbrxConfirmPopup',
  components: {LbrxButton},
  props: {
    modelValue: Boolean,
    queue_lines: Array
  },
  data() {
    return {
      sending: false,
      close: false,
      showPopup: false,
    }
  },
  methods: {
    triggerPopupAction() {
      this.$emit('confirm')
      this.closeThis()
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close')
      }, 700)
    },
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  }
}
</script>

<style scoped>
.popup-elements {
  overflow-x: hidden;
  height: 100%;
}

.confirm-popup-body {
  height: 100%;
  display: flex;

  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  text-align: center;

  color: #8A8A8A;
}

.popup-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

</style>