<template>
  <div :class="{'right-class': $i18n.locale === 'ar'}" ref="customerTicketContainer" class="ticket-class">
    <div class="ticket-title justify-content-between">
      <div>{{ $t('Teller.Current_client') }}</div>
      <div v-if="modelValue.item!== null" style="display: flex">
        <div id="clientStatus" :style="{'color': modelValue.item.status === 'waiting' ? '#FFCE00' : '#93F383'}"
             style="margin-right: 10px;margin-top: auto;margin-bottom: auto">
          {{ $t('Teller.' + this.modelValue.item.status) }}
        </div>
        <div class="status status-warning mx-1" style="margin:auto"
             :class="{'online': modelValue.item.status !== 'waiting'}"
             :style="{height: 'calc(0.5*'+1.5+'*(1vh + 1vw))', width: 'calc(0.5*'+1.5+'*(1vh + 1vw))'}">
        </div>
      </div>
    </div>
    <div v-if="modelValue.item !== null" class="row ticket-container">
      <div class="col-sm">
        <div class="user-class mx-0">
          <div class="image-container">
            <img class="user-image" src="../assets/images/avatar.png"/>
          </div>
          <div class="user-info mx-4 my-auto" v-if="modelValue.item!== null" style="direction: initial!important;">
            <span id="userFName" v-if="modelValue.item.fname" class="user-name">{{ modelValue.item.fname }}</span>
            <span id="userLName" v-if="modelValue.item.lname" class="user-name">{{ ' ' + modelValue.item.lname }}</span>
            <span v-if="modelValue.item.internal_id != null" class="mx-2" :style="{'color':'#FFCE00'}">{{modelValue.item.internal_id}}</span>
            <span id="userAnonym" v-if="modelValue.item.lname === null && modelValue.item.fname === null "
                  class="user-name">{{ $t('Teller.Anonyme') }}</span><br>
            <span id="userEmail" v-if="modelValue.item.email" class="user-email">{{ modelValue.item.email }}</span><br>
            <span id="userPhoneNumber" v-if="modelValue.item.phone_number"
                  class="user-phone">{{ modelValue.item.phone_number }}</span><br>
          </div>
        </div>
        <div class="details-container">
          <div>
            <lbrx-statistics-table class="statBox" :stat="stat"></lbrx-statistics-table>
            <div class="row p-0 mx-0 my-2" :class="{'right-class': $i18n.locale === 'ar'}">
              <lbrx-sub-menu-button id="createBooking" icon="calendar-icon" :label="$t('Teller.Booking')"
                                    color="#008FCA"
                                    @click="this.$emit('appointment')"
                                    background="#F2F2F2"
                                    height="4vmin" width="auto" font-size="0.6"
                                    :full-color="true"></lbrx-sub-menu-button>
              <lbrx-sub-menu-button :visible="teller_config.business.ticket_price_status=== 1" id="openPayment"
                                    icon="dollar" :label="$t('Teller.Payment')"
                                    color="#008FCA" background="#F2F2F2"
                                    @click="this.$emit('payment')"
                                    height="4vmin" width="auto" font-size="0.6"
                                    :full-color="true"></lbrx-sub-menu-button>
            </div>

          </div>
          <hr>
          <div style="padding-bottom: 10px;padding-top: 10px" class=" mx-0 mt-0">
            <div class="user-info mt-2">
              <span class="info-title">{{ $t('BookingDetails.Services') }} :</span>
              <span :id="'currentTicketService-'+service.id"
                    v-for="(service, index) in serviceNames(modelValue.item.service_names)" :key="index"
                    class="service-item service-name mx-1 my-auto"> {{ index }} (x{{ service.length }})</span>

              <!-- <table>
                 <tr >
                -- <td style="width: unset;border: unset">
                     <img src="./../assets/icons/053-edit.svg" class="mx-1 mt-1 cursor">
                   </td>
                   <td style="width: unset;border: unset">
                     <img src="./../assets/icons/074-bin.svg" class="mx-1 mt-1 cursor">
                   </td>
                   <td>

                   </td>

                </tr>
              </table>-->

            </div>
          </div>
          <hr v-if="modelValue.lastNote">
          <div v-if="modelValue.lastNote" style="padding-bottom: 10px;padding-top: 10px" class=" mx-0 mt-0">
            <div class="user-info mt-2">
              <span class="info-title">{{ $t('Teller.LastNote') }} :</span>
              <span class="service-item service-name mx-1 my-auto"> {{ modelValue.lastNote.note }}</span>

            </div>
          </div>
        </div>
      </div>
      <div class="col-3 pub-container mx-auto my-auto d-none d-sm-block">
        <img v-if="stat.pub" :src="stat.pub" class="pub-image" alt="">
      </div>

    </div>
    <div id="noTicket" class="no-data-container" v-else>
      <div class="statistics-image-container">
        <img src="../assets/images/no-ticket-data.png"/>
      </div>
      <div class="no-data-label">{{ $t('Teller.No_ticket') }}</div>
    </div>
  </div>
</template>
<script>
import LbrxSubMenuButton from "@/components/LbrxSubMenuButton.vue";
import LbrxStatisticsTable from "@/components/LbrxStatisticsTable.vue";

export default {
  name: 'LbrxCurrentTicketInfoBox',
  components: {LbrxStatisticsTable, LbrxSubMenuButton},
  data() {
    return {
      maxHeight: '150px',
      teller_config: this.teller_config = JSON.parse(localStorage.getItem("user") || "{}")
    }
  },
  props: {
    modelValue: Object,
    stat: Object,
  },
  methods: {
    serviceNames(services) {
      services = ' ' + services
      return services.split(',').reduce((group, product) => {
        group[product] = group[product] ?? [];
        group[product].push(product);
        return group;
      }, {})
    }
  },
  computed: {
    getMaxHeight() {
      return (this.$refs.customerTicketContainer.offsetHeight / 1.2) + "px";
    },
    getImageHeight() {
      return (this.$refs.customerTicketContainer.offsetHeight / 1.4) + "px";
    },
  }
}
</script>

<style scoped>
.ticket-container {
  min-height: 42vh;
}

@media (max-width: 767px) {
  .ticket-container {
    min-height: auto !important;
  }

  .statBox {
    display: none;
  }
}

@media (max-width: 680px) {
  .ticket-class {
    min-height: 39.6vh !important;
    height: fit-content !important;
  }

  .no-data-container {
    height: 100%;
    margin-top: -10vmin !important;
  }

  .statistics-image-container img {
    height: 25vmin !important;
  }

  .statistics-image-container {
    margin-top: 20vmin !important;
  }

  .no-data-label {
    margin-top: 3vmin !important;
    font-size: 5.4vmin !important;
    line-height: 6vmin;
    color: #E3E3E3;
  }

  .user-email, .user-phone, .user-info, .service-item {
    font-size: 3vmin !important;
  }
}

.td-title {

  background: linear-gradient(127.19deg, #008FCA 0.56%, #4BB0DA 76.01%);
  opacity: 0.9;
}

tr {
  border: 4px solid white;
}

td {
  border: 4px solid white;
  width: fit-content;
  height: fit-content;
}

td:first-child {
  border: 4px solid white;
  width: fit-content;
}

.td-item {
  background: #F8F8F8;
  /* Primary */

  border-bottom: 1px solid #008FCA;

}

.pub-image {
  height: v-bind(getImageHeight);
}

.text-info {
  margin-left: 20px;
  margin-right: 20px;
  width: fit-content;
  font-size: 2.4vmin;
  line-height: 2vmin;
}

.user-image {
  width: 40%;
  height: 50%;
}

.user-info {
  word-break: break-all;
  margin-left: 10px;
  margin-top: -1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-size: 2.4vmin;
  line-height: 2vmin;

  /* Gray 4 */

  color: #BDBDBD;
}

.user-sub-info {
  word-break: keep-all !important;
  margin-left: 20px;
  margin-right: 20px;
  width: fit-content;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-size: 2.4vmin;
  line-height: 2vmin;

  /* Gray 4 */

  color: #BDBDBD;
}


.service-name {
  font-weight: 400;
  font-size: 2vmin;
  line-height: 2vmin;
}

.user-phone {
  font-weight: 400;
  font-size: calc(0.7 * (1vh + 1vw));
}

.user-email {
  font-weight: 400;
  font-size: calc(0.7 * (1vh + 1vw));
}

.user-name {
  font-weight: 700;
}

.image-container {
  box-sizing: border-box;
  border-radius: 74px;
  width: 60px;
  height: 60px;
  display: flex;
  padding-top: 18px;
  justify-content: center;
  margin: 0;
  border: double 2px transparent;
  background-image: linear-gradient(#e5e5e5, #e5e5e5), radial-gradient(circle at top left, #008FCA, #4BB0DA);
  background-origin: border-box;
  background-clip: padding-box, border-box;


}

.cursor:hover {
  cursor: pointer;
}

.cursor {
  width: 15px;
}

.user-class {
  display: flex;
  margin: 2vmin;
  margin-bottom: 1vmin;
}

.ticket-title {
  width: 100%;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4vmin;
  line-height: 2vmin;
  display: flex;

  /* Medium Gray */

  color: #8A8A8A;

}

.ticket-class {
  width: auto;
  padding: 20px;
  height: 100%;
  background: white;
}

.no-data-container {
  margin: 4vmin;
  min-height: 28vmin;
}

.pub-container {
  text-align: end;
}

@media (max-width: 1100px) {
  .pub-container {
    display: none !important;
  }
}

.statistics-image-container img {
  height: 16vmin;
}

.statistics-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 9vmin;
}

.no-data-label {
  width: 100%;
  text-align: center;
  margin-top: 1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.4vmin;
  line-height: 4vmin;

  /* Gray Light */

  color: #E3E3E3;
}

.info-title {
  color: #008FCA !important;
}

.service-item {
  font-weight: 300;
  font-size: 2vmin;
  line-height: 2vmin;
  margin: unset;
  word-break: keep-all;
}

.details-container {
  overflow-y: clip;
  max-height: 37vmin;
}

hr {
  margin: 10px 0 !important;
}
</style>