<template>
  <ul :class="{'right-class': $i18n.locale === 'ar'}" v-if="pages" class="pagination">
    <li @click="$emit('previousPage')" class="page-item page-link">
      <div style="display: flex;height: 100%">
        <img style="margin: auto" v-if="$i18n.locale !== 'ar'" class="pagination-icon"
             src="../assets/icons/paginator-left-arrow.svg"/>
        <img style="margin: auto" v-else class="pagination-icon" src="../assets/icons/paginator-right-arrow.svg"/>
      </div>
    </li>

    <li v-if="modelValue>2"
        class="page-item page-link">
      ...
    </li>
    <div v-for="index in pages" :key="index">
      <li @click="$emit('specificPage', index)"
          :class="{'active-pagination-item': index === modelValue}"
          class="page-item page-link"
          v-if="index === modelValue-1 || index === modelValue || index === modelValue+1">
        {{ index }}
      </li>
    </div>
    <li v-if="modelValue<pages-1"
        class="page-item page-link">
      ...
    </li>

    <li @click="$emit('nextPage')" class="page-item page-link">

      <div style="display: flex;height: 100%">
        <img style="margin: auto" v-if="$i18n.locale !== 'ar'" class="pagination-icon"
             src="../assets/icons/paginator-right-arrow.svg"/>
        <img style="margin: auto" v-else class="pagination-icon" src="../assets/icons/paginator-left-arrow.svg"/>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LbrxPaginationNumbers',
  props: {
    pages: Number,
    modelValue: Number,
  },
  emits: ['specificPage', 'previousPage', 'nextPage'],
}
</script>
<style scoped>
.page-link {
  cursor: pointer;
  background: #F2F2F2;
  /* Gray Light */
  border-radius: 0;
  border: 1px solid #E3E3E3;
  margin-left: 0.2vmin;
  margin-right: 0.2vmin;

  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 2.2vmin;

  /* Primary */

  color: #008FCA;
}

.pagination-icon {
  height: 1.5vmin;
  margin-top: -0.6vmin;
}

.active-pagination-item {
  background: #008FCA;
  /* Light Blue */

  border: 1px solid #4BB0DA;
  color: #FFFFFF;
}
</style>