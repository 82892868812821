<template>
  <div v-if="modelValue" class="popup-container" :class="{'right-class': $i18n.locale === 'ar'}">
    <Transition>
      <div v-if="showPopup" class="popup-box">
        <div class="container d-flex flex-column h-100">
          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon mx-3" src="../assets/icons/language-icon.svg" alt=""/>
                <div class="popup-title-text">
                  {{$t('Teller.Language')}}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{$t('Teller.Language_details')}}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>
          <div class="row">
            <div v-if="!loading" class="popup-body">
              <div class="language-container" >
                <lbrx-button icon="french" @clicked="setLanguage('fr')" label="Français" color="#8A8A8A"
                             background="white" height="9.5vmin" width="100%" font-size="1"
                             border="1px solid #EAEAEA" ></lbrx-button>
                <lbrx-button icon="english" @clicked="setLanguage('en')" label="English" color="#8A8A8A"
                             background="white" height="9.5vmin" width="100%" font-size="1"
                             border="1px solid #EAEAEA"></lbrx-button>
                <lbrx-button icon="arabic" @clicked="setLanguage('ar')" label="العربية" color="#8A8A8A"
                             background="white" height="9.5vmin" width="100%" font-size="1"
                             border="1px solid #EAEAEA"></lbrx-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

import LbrxFooterButton from "@/components/LbrxFooterButton.vue";
import LbrxButton from "@/components/LbrxButton.vue";

export default {
  name: 'LbrxLanguageSelectPopup',
  inject: ['direction','text_direction'],
  components: {LbrxButton},
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      showPopup: false,
      loading: true,
    }
  },
  methods: {
    setLanguage(lang) {
      localStorage.setItem('Language', lang)
      this.$i18n.locale = localStorage.getItem('Language')
      location.reload()
      this.closeThis();
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close');
      }, 700)
    },
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });
    this.loading = false;
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  },
}
</script>

<style scoped>
@media (max-width: 1200px) {
  .popup-box {
    width: 70% !important;
  }
}

.language-container {
  display: flex;
  flex-direction: column;
  margin-top: 5vmin;
}

.popup-box {
  width: 28%;
}

</style>