<template>
  <div v-if="times.length" style="display: flex;justify-content: center;width: 100% ;padding: inherit;" >
    <div ref="timesBox" class="times-box" :style="{'padding-right': $i18n.locale === 'ar'?'2vmin !important':'','padding-left': $i18n.locale === 'ar'?'':'2vmin !important'}">
      <div class="row">
        <div class="singleTimeSlot col-3" v-for="(time,index) in times" :key="index" @click="selectTime(time)"
             :class="{'disabled': !time.active, 'selected': selectedTime===time}">
          {{ time.slot }}
        </div>
      </div>
    </div>
  </div>
  <div class="times-box" style="display: flex" v-else-if="modelValue"
       :style="{'padding-right': $i18n.locale === 'ar'?'2vmin !important':'','padding-left': $i18n.locale === 'ar'?'':'2vmin !important'}">
    <div class="spinner-border" :style="{'color':secondaryColor}"
         style="margin: auto;height: 9vmin;width: 9vmin;font-size: 3vmin" role="status">
    </div>
  </div>
  <div v-else class="times-box" style="display: flex;justify-content: center"
       :style="{'padding-right': $i18n.locale === 'ar'?'2vmin':'','padding-left': $i18n.locale === 'ar'?'':'2vmin'}" >
    <div style="margin: auto">
      {{$t('BookingPopup.No-slot')}}
    </div>
  </div>
</template>
<script>
import colorShader from "@/_helpers/color-shader";

export default {
  name: 'LbrxBookingTimePicker',
  props: {
    times: {required: true, type: Array},
    modelValue: {required: true, type: Boolean},
    textColor: {required: true, type: String},
    primaryColor: {required: true, type: String},
    secondaryColor: {required: true, type: String},
  },
  data() {
    return {
      maxSlotsCount: 0,
      selectedTime: null,
    }
  },
  watch: {
    modelValue: {
      handler: function () {
        this.maxSlotsCount = 0;
      },
      deep: true
    }
  },
  emits: ['timeSelected'],
  computed: {
  /*  hours() {
      const hours = [];
      let j = 0;
      hours[j] = [];
      hours[j].times = [];
      hours[j].times.push(this.times[j]);
      for (let i = 1; i < this.times.length; i++) {
        if (this.times[i].slot.split(":")[0] === hours[j].times[hours[j].times.length - 1].slot.split(":")[0]) {
          hours[j].times.push(this.times[i]);
          if (this.maxSlotsCount < hours[j].times.length)
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.maxSlotsCount = hours[j].times.length;
        } else {
          if (this.maxSlotsCount < hours[j].times.length)
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.maxSlotsCount = hours[j].times.length;
          j++;
          hours[j] = [];
          hours[j].times = [];
          hours[j].times.push(this.times[i]);
        }
      }
      return hours;
    },
    singleSlotsWidth() {
      return 85 / this.maxSlotsCount + '%';
    },

   */
    makeSecondaryColorLighter() {
      return colorShader.methods.newShade(this.secondaryColor, 50);
    },
    makeSecondaryColorDarker() {
      return colorShader.methods.newShade(this.secondaryColor, -50);
    },
    makeColorDarker() {
      return colorShader.methods.newShade(this.textColor, -50);
    },
    makeColorLighter() {
      return colorShader.methods.newShade(this.textColor, 50);
    },
  },
  methods: {
    selectTime(time) {

      if (!time.active)
        return;

      if (time === this.selectedTime) {
        this.selectedTime = null;
      } else {
        this.selectedTime = time;
      }
      this.$emit('timeSelected', this.selectedTime);
    },
  },
}
</script>

<style scoped>
.times-box {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.singleTimeSlot {
  width: 21%;
  cursor: pointer;
  margin: 0.3em;
  height: 2em;
  display: flex;
  justify-content: center;
  border: 1px solid v-bind(secondaryColor);
  color: v-bind(secondaryColor);
  padding-top: 0.5em;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vmin;
  line-height: 1.5vmin;
}

.singleTimeSlot:hover {
  opacity: 0.8;
}

.disabled {
  border: 1px solid #A4A2A1;
  color: #A4A2A1;
  cursor: default;
}

.selected {
  background: v-bind(secondaryColor) !important;
  color: v-bind(textColor)
}

.disabled:hover {
  opacity: 1;
}
</style>