<template>
    <div :style="{'background':background, 'color':color, 'height': height+'vmin','width': width, 'font-size': 'calc('+fontSize+'*(1vh + 1vw))','padding-top': 'calc('+height+'*(0.1vh + 0.1vw))'}" class="error-box">
       <img v-if="icon" class="icon-style" :src="getIcon()" alt=""/><span v-if="label">{{label}}</span>
    </div>
</template>
<script>
export default {
    name: 'LbrxErrorNotification',
    props: {
        fontSize:{
            required: true,
            value: String
        },
        height:{
            required: true,
            value: String
        },
        width:{
            required: true,
            value: String
        },
        background:{
            required: true,
            value: String
        },
        color:{
            required: true,
            value: String
        },
        label:{
            required: true,
            value: String
        },
        icon:{
            required: true,
            value: String
        },
    },
    methods: {
        getIcon() {
            if(this.icon) {
                var images = require.context('../assets/')
                return images('./icons/' + this.icon + ".svg")
            }
        },
    }
}
</script>

<style scoped>
.error-box{
    font-family: 'Inter',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0 auto;
}
.icon-style{
    height: 3.5vmin;
    z-index: 99999;
    margin-right: 3vmin;
}
</style>