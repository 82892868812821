import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomePage from "@/views/home/HomePage.vue";
import MaintenacePage from "@/views/maintenance/MaintenacePage.vue";
import LoginPageNoRecaptcha from "@/views/login/LoginPageNoRecaptcha.vue";
import LoginPageWithCaptcha from "@/views/login/LoginPageWithCaptcha.vue";
import LoginPageAD from "@/views/login/LoginPageAD.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/login',
    name: 'LoginPagev3',
    component: LoginPageWithCaptcha
  },
  // {
  //   path: '/login/ad',
  //   name: 'LoginPageAD',
  //   component: LoginPageAD
  // },
  {
    path: '/login/no-recaptcha',
    name: 'LoginPageNoRecaptcha',
    component: LoginPageNoRecaptcha
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      authRequired: true,
      authorize: ["*"],
    },
    component: HomePage
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: {
      authRequired: true,
      authorize: ["*"],
    },
    component: MaintenacePage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {

  const userLoggedIn = localStorage.getItem('user') || null;
    if (to.meta.authRequired && userLoggedIn === null) {
      return next('/login');
    }

  if (to.path == '/login' && userLoggedIn !== null) {
    return next('/');
  }

    if (to.name == "Host") {
        if(!process.env.VUE_APP_EDIT_HOST || process.env.VUE_APP_EDIT_HOST == "false") {
            return next('/home');
        }
    }

    next();
})

export default router