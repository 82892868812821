<template>
  <div class="ticket-status">
    <span style="color: #008FCA" v-if="status === 'inservice'">{{ $t('Teller.inservice') }}</span>
    <span style="color: #008FCA" v-if="status === 'end'">{{ $t('Teller.end') }}</span>
    <span style="color: #FFCE00" v-if="status === 'waiting'">{{ $t('Teller.waiting') }}</span>
    <div class="status mx-1" :class="{'status-warning': status === 'waiting', 'status-info' : status === 'inservice'}"></div>
  </div>
</template>

<script>
export default {
  name: 'LbrxTicketStatus',
  props: {
    status: String,
  }
}
</script>

<style scoped>
.ticket-status {
  font-size: 1.4vmin;
  width: 92%;
  display: flex;
}

.status {
  margin-top: 0.1vmin;
  border-radius: 300px;
  width: 1.7vmin;
  height: 1.7vmin;
}
</style>