<template>
  <div v-if="modelValue" class="queue-summary-box">
    <div :class="{'right-class': $i18n.locale === 'ar'}" class="statistics-title">{{$t('Teller.queue')}}</div>
    <div class="queue-summary-container">
      <div class="container">
        <div class="row" >
          <div class="col-4 summary-element border-right">
            <div id="totalWaiting" class="summary-title summary-value">
              {{ modelValue.totalWaiting ?? "0" }}
            </div>
            <div class="summary-title"  :class="{'right-class': $i18n.locale === 'ar'}">
              <img src="../assets/icons/radio-button-grey-icon.svg">
              {{$t('Teller.Waiting')}}
            </div>
          </div>
          <div class="col-4 summary-element border-right">
            <div id="servedToday" class="summary-title summary-value">
              {{ modelValue.servedToday ?? "0" }}
            </div>
            <div class="summary-title" :class="{'right-class': $i18n.locale === 'ar'}">
              <img src="../assets/icons/total-serv.svg">
              {{$t('Teller.ServedToday')}}
            </div>
          </div>
          <div class="col-4 summary-element">
            <div id="totalWaitingBooking" class="summary-title summary-value">
              {{ modelValue.totalWaitingBooking ?? "0" }}
            </div>
            <div class="summary-title" :class="{'right-class': $i18n.locale === 'ar'}">
              <img src="../assets/icons/calendar-grey-icon.svg" width="20">
              {{$t('Teller.Bookings')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'LbrxQueueSummary',

  props: {
    modelValue: Object,
  },
}
</script>
<style scoped>
.border-right {
  border-right: 1px solid #E0E0E0;
}

.summary-value {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  margin-top: 0 !important;
  font-size: 62px !important;
  line-height: 3vmin !important;

  /* Primary */

  color: #008FCA !important;
}

.summary-title {
  margin-top: 25px !important;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8vmin;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center!important;
  letter-spacing: 0.1vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.queue-summary-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -1vmin;
  margin-left: auto;
  margin-right: auto;
}

.summary-element {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.statistics-title {
  font-family: 'Exo 2', serif;
  font-style: normal;
  margin-bottom: 1vmin;
  min-width: 40vmin !important;
  font-weight: 400 !important;
  font-size: 2.4vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.queue-summary-box {
  padding: 21px;
  width: 100% !important;
  min-height: 21vh !important;
  background: white;
}
</style>