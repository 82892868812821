<template>
  <div class="home-container">
    <div class="home-header justify-content-between" :class="{'right-class': $i18n.locale === 'ar'}">
      <div :class="{'right-class': $i18n.locale === 'ar'}" id="userName" class="header-user mx-3">
        {{ $t('Teller.HelloCustomer') }} <b>{{ user.name }},</b><br>
        <span class="greetings">{{ $t('Teller.HelloWord') }}</span>
      </div>
      <div class="header-tools" :class="{'right-class': $i18n.locale === 'ar'}">
        <div class="setting-container" :class="{'right-class': $i18n.locale === 'ar'}">
          <div class="settings-items-container">
            <div class="screen-settings">
              <div class="setting-item">
                          <span id="currentQueue" :class="{'right-class': $i18n.locale === 'ar'}" class="setting-title">
                              {{ $t('Teller.queue') }}
                          </span>
                <span id="queueTitle" :class="{'right-class': $i18n.locale === 'ar'}" v-if="queue"
                      class="setting-title setting-data">
                            {{ queue.title ?? '--------' }}
                          </span>
                <span :class="{'right-class': $i18n.locale === 'ar'}" v-else class="setting-title setting-data">
                            ---------------
                          </span>
              </div>
              <div id="currentCounter" class="setting-item">
                          <span :class="{'right-class': $i18n.locale === 'ar'}" class="setting-title">
                              {{ $t('Teller.Counter') }}
                          </span>
                <span :class="{'right-class': $i18n.locale === 'ar'}" v-if="counter_config"
                      class="setting-title setting-data">
                                {{ counter_config.name ?? '--------' }}
                          </span>
                <span :class="{'right-class': $i18n.locale === 'ar'}" v-else class="setting-title setting-data">
                                --------
                          </span>
              </div>
            </div>

            <div class="mobile-settings">
              <div id="queueTitle" class="setting-item">
                          <span v-if="queue" class="setting-title">
                              {{ queue.title ?? '--------' }}
                          </span>
                <span v-else class="setting-title">
                            ---------------
                          </span>
                <span v-if="counter_config" class="setting-title">
                                {{ counter_config.name ?? '--------' }}
                          </span>
                <span v-else class="setting-title">
                                --------
                          </span>
              </div>
            </div>
          </div>
          <div :style="{'background': counter_config ? '#4DAE2B' : '#FF5757'}" class="setting-decoration"></div>
        </div>

        <div class="setting-icon-container">
          <div class="icon-container mx-auto" style="align-self: flex-end;">
            <img @click="showConfigPopup = true" src="../../assets/icons/settings-icon.svg" class="setting-icon"/>
          </div>
          <div class="setting-icon-decoration"></div>
        </div>
        <div class="setting-icon-container">
          <div class="icon-container mx-auto" style="align-self: flex-end;">
            <img @click="logout()" src="../../assets/icons/010-logout.svg" class="setting-icon mx-3"/>
          </div>
          <div class="logout-icon-decoration"></div>
        </div>
      </div>
    </div>
    <div class="second-home-header justify-content-between" :class="{'right-class': $i18n.locale === 'ar'}">
      <div class="second-header-buttons">

        <div @click="showDevicesPopup = true; devicesType='displays'"
             :style="{'margin-right': $i18n.locale ==='ar'?'2vmin':'unset','margin-left': $i18n.locale ==='ar'?'unset':'2vmin'}">
          <lbrx-sub-menu-button id="displaysButton" v-model="displays" icon="affichage-icon"
                                :label="$t('Teller.displays')"
                                color="#8A8A8A"
                                background="white" height="5vmin" width="auto" font-size="0.6"></lbrx-sub-menu-button>
        </div>

        <div class="vertical-hr"></div>

        <div @click="showDevicesPopup = true; devicesType='kiosks'">
          <lbrx-sub-menu-button id="kiosksButton" v-model="kiosks" icon="billing-machine-icon"
                                :label="$t('Teller.kiosks')"
                                color="#8A8A8A" class="mx-2"
                                background="white" height="5vmin" width="auto" font-size="0.6"></lbrx-sub-menu-button>
        </div>

      </div>

      <div class="second-header-buttons right-buttons">
        <lbrx-sub-menu-button id="showQueueButton" v-model="noStatus"
                              @clicked="queueType = 'queue',showQueuePopup = true"
                              v-if="queue"
                              icon="file-attente-icon" :label="$t('Teller.Queue')" color="#008FCA" background="white"
                              height="5vmin" width="22vmin" font-size="0.6"></lbrx-sub-menu-button>
        <div class="vertical-hr" v-if="queue"></div>
        <lbrx-sub-menu-button id="showArchiveButton" v-model="noStatus"
                              @clicked="queueType = 'archive',showQueuePopup = true"
                              v-if="queue"
                              icon="historique-icon" :label="$t('Teller.Archive')" color="#008FCA" background="white"
                              height="5vmin" width="22vmin" font-size="0.6"></lbrx-sub-menu-button>
        <div class="vertical-hr" v-if="queue"></div>
        <lbrx-sub-menu-button id="showBookingsButton" v-model="noStatus" @clicked="showBookingPopup = true"
                              icon="calendar-icon"
                              :label="$t('Teller.Bookings')" color="#008FCA" background="white" height="5vmin"
                              width="22vmin"
                              font-size="0.6"></lbrx-sub-menu-button>
        <div class="vertical-hr"></div>
        <lbrx-sub-menu-button id="userManualButton" v-model="noStatus" icon="info-icon"
                              :label="$t('Teller.User_manual')" color="#008FCA"
                              background="white" height="5vmin" width="22vmin" font-size="0.6"></lbrx-sub-menu-button>


      </div>
    </div>

    <div id="loader" v-if="(sending && queue)"
         :style="{'background':(sending && queue)?'rgba(255,255,255,0.87)':'unset'}"
         style="position: absolute;width: 100%;height: 100%;z-index: 10001522283">
      <div id="secondLoader" v-if="(sending && queue)" class="spinner-border" :style="{'color': '#008fca'}"
           style="margin: auto;height: 9vmin;width: 9vmin;font-size: 3vmin;position: absolute; top: 50%; right: 50%;"
           role="status">
      </div>
    </div>
    <div class="main-vue">
      <div class="row">
        <div class="col-md-9 m-0 p-0">
          <div class="row m-0 p-0">
            <div class="col-md-9 m-0 p-0">
              <div v-touch:swipe="swipeHandler" class="box mobile-container vertical-ticket-h h-100">
                <lbrx-current-ticket-info-box v-if="toDisplay == 1 " v-model="currentTicket" class="m-2 m-md-0"
                                              :stat="customerStats"
                                              v-on:payment="showPaymentPopup = true"
                                              v-on:appointment="appointmentType = null;showAppointmentPopup = true"
                                              v-on:confirm-payment="payment($event)"></lbrx-current-ticket-info-box>
                <div v-else style="height: 95% !important;display: flex;background: white;margin: 9px !important;">
                  <div v-if="toDisplay === 2"
                       style="height: 100%;width: 100%;background: white;display: flex;justify-content: center">
                    <div style="margin: auto">
                      <lbrx-statistics-table :stat="customerStats"></lbrx-statistics-table>
                    </div>
                  </div>
                  <div v-else-if="toDisplay === 3" style="margin-top: auto;margin-bottom: auto;width: 99%">
                    <lbrx-ticket-on-hold style="margin-top: auto;margin-bottom: auto" class="m-2 m-md-0"
                                         v-model="counter_config" :waiting_list="onHoldTickets"
                                         v-on:endTicket="endTicket($event,'hold')"></lbrx-ticket-on-hold>
                  </div>
                  <lbrx-queue-summary style="margin-top: auto;margin-bottom: auto" class="m-2 m-md-0"
                                      v-else-if="toDisplay === 4" v-model="queueSummary"></lbrx-queue-summary>
                  <lbrx-statistics-box style="margin-top: auto;margin-bottom: auto;width: 100%;height: 100%"
                                       class="m-2 m-md-0" v-else-if="toDisplay === 5" v-model="counterStats"
                                       :has-amount="hasAmount"></lbrx-statistics-box>
                </div>
                <div v-if="items" class="pointers home-pointers my-2">
                  <div v-for="item in items" :key="item" class="pointer"
                       :class="{'active-pointer': item === toDisplay}"></div>
                </div>
              </div>
            </div>
            <div class="col-md-3 m-0 p-0">
              <div class="vertical-ticket box vertical-ticket-h h-100 vertical-ticket-component">
                <LbrxTicketVertical v-model="currentTicket" :counter_config="counter_config"
                                    v-on:Redirect-ticket="showRedirectPopup=true"
                                    v-on:Re-callTicket="recallTicket()"
                                    v-on:Note="showNotePopup = true"></LbrxTicketVertical>
              </div>
            </div>
          </div>
          <div class="row m-0 p-0">
            <div class="col-md-6 d-none d-lg-block box bottom-containers">
              <lbrx-ticket-on-hold v-model="counter_config" :waiting_list="onHoldTickets"
                                   v-on:returnOnHoldTicket="returnOnHoldTicket($event)"

                                   v-on:endTicket="endTicket($event,'hold')"></lbrx-ticket-on-hold>
            </div>
            <div class="col-md-6 d-none d-lg-block box vertical-ticket-h vertical-ticket bottom-containers">
              <lbrx-queue-summary v-model="queueSummary"></lbrx-queue-summary>
            </div>
          </div>
        </div>

        <div class="col-md-3 m-0 p-0">
          <div class="row h-100 m-0 p-0 right-boxes">
            <div class="h-auto m-0 p-0 d-none d-lg-block box vertical-ticket stat-container">
              <lbrx-statistics-box v-model="counterStats" :has-amount="hasAmount"></lbrx-statistics-box>
            </div>
            <div class="h-auto m-0 p-0 box console vertical-ticket">
              <lbrx-teller-console v-model="sending" :ticket="currentTicket.item"
                                   @sending="sending = true"
                                   v-on:resetstat="resetCustomerStat()"
                                   v-on:callNext="updateCurrentTicketData($event)"
                                   v-on:holdTicket="holdTicket()"
                                   v-on:ticketAbsent="ticketAbsent()"
                                   v-on:endTicket="showConfirmPopup = true"></lbrx-teller-console>
            </div>
          </div>
        </div>
      </div>
    </div>
    <lbrx-toast id="lbrxToast" v-if="showToastPopup" v-model="showToastPopup" :type="toastType" :message="toastData"
                :nb_notifications="notifications"
                @close="showToastPopup = false"></lbrx-toast>
    <div class="footer-container" ref="footerContainer">
      <the-footer :internet="onLine"></the-footer>
    </div>
    <lbrx-session-configuration-popup v-if="showConfigPopup && queues.length>0"
                                      @close="configClosed($event)"
                                      @unsubscribe="unsubscribeToPusher($event)"
                                      v-model="showConfigPopup"
                                      :queue_lines="queues"></lbrx-session-configuration-popup>


    <lbrx-booking-popup v-if="showBookingPopup" @close="showBookingPopup = false"
                        v-model="showBookingPopup"></lbrx-booking-popup>
    <lbrx-queue-popup v-if="showQueuePopup" @close="showQueuePopup = false" v-model="showQueuePopup"
                      :type="queueType" :queue="queue"></lbrx-queue-popup>
    <lbrx-redirect-popup v-if="showRedirectPopup && queues.length>0"
                         v-model="showRedirectPopup" @close="showRedirectPopup = false"
                         :queue_lines="queues"
                         v-on:Redirect-trigger="Redirect($event)"></lbrx-redirect-popup>

    <lbrx-confirm-popup v-if="showConfirmPopup"
                        v-model="showConfirmPopup" @close="showConfirmPopup = false"
                        v-on:confirm="endTicket(currentTicket.item ? currentTicket.item.public_identifier : null,null)"></lbrx-confirm-popup>

    <lbrx-note-popup v-if="showNotePopup" :note="currentTicket.item.notes" :type="currentTicket.item.note_type"
                     v-model="showNotePopup" @close="showNotePopup = false"
                     v-on:confirm-note="updateComment($event)"></lbrx-note-popup>

    <lbrx-payment-popup v-if="showPaymentPopup" :ticket="this.currentTicket.item"
                        v-model="showPaymentPopup" @close="showPaymentPopup = false"
                        v-on:confirm-payment="payment($event)"></lbrx-payment-popup>

    <lbrx-appointment-popup v-if="showAppointmentPopup"
                            v-model="showAppointmentPopup" :current_client="currentTicket.item" :queues="queues"
                            :type="appointmentType"
                            @close="showAppointmentPopup = false;appointmentType='new'"></lbrx-appointment-popup>

    <lbrx-devices-popup v-if="showDevicesPopup" @close="showDevicesPopup = false" v-model="showDevicesPopup"
                        :type="devicesType" :items="devicesType === 'displays'?displays:kiosks"></lbrx-devices-popup>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import {businessService, counterService, queueService} from "../../_services";
import LbrxSubMenuButton from "@/components/LbrxSubMenuButton.vue";
import LbrxCurrentTicketInfoBox from "@/components/LbrxCurrentTicketInfoBox.vue";
import TheFooter from "@/components/TheFooter.vue";
import LbrxTicketVertical from "@/components/LbrxTicketVertical.vue";
import LbrxStatisticsBox from "@/components/LbrxStatisticsBox.vue";
import LbrxTellerConsole from "@/components/LbrxTellerConsole.vue";
import LbrxQueueSummary from "@/components/LbrxQueueSummary.vue";
import LbrxTicketOnHold from "@/components/LbrxTicketOnHold.vue";
import LbrxSessionConfigurationPopup from "@/components/LbrxSessionConfigurationPopup.vue";
import LbrxBookingPopup from "@/components/LbrxBookingPopup.vue";
import LbrxQueuePopup from "@/components/LbrxQueuePopup.vue";
import router from "@/router";
import moment from "moment/moment";
import LbrxDevicesPopup from "@/components/LbrxDevicesPopup.vue";
import LbrxRedirectPopup from "@/components/LbrxRedirectPopup.vue";
import LbrxToast from "@/components/LbrxToast.vue";
import LbrxConfirmPopup from "@/components/LbrxConfirmPopup.vue";
import LbrxNotePopup from "@/components/LbrxNotePopup.vue";
import LbrxPaymentPopup from "@/components/LbrxPaymentPopup.vue";
import LbrxAppointmentPopup from "@/components/LbrxAppointmentPopup.vue";
import LbrxStatisticsTable from "@/components/LbrxStatisticsTable.vue";
import store from "@/store";
import Echo from "laravel-echo";
import {ApiConfigs, authHeader} from "@/_helpers";


export default {
  name: "HomePage",
  //inject: ['echo'],
  components: {
    LbrxStatisticsTable,
    LbrxAppointmentPopup,
    LbrxToast,
    LbrxRedirectPopup,
    LbrxDevicesPopup,
    LbrxQueuePopup,
    LbrxBookingPopup,
    LbrxSessionConfigurationPopup,
    LbrxTicketOnHold,
    LbrxQueueSummary,
    LbrxTellerConsole,
    LbrxStatisticsBox,
    LbrxTicketVertical,
    TheFooter,
    LbrxCurrentTicketInfoBox,
    LbrxSubMenuButton,
    LbrxConfirmPopup,
    LbrxNotePopup,
    LbrxPaymentPopup
  },
  data() {
    return {
      noStatus: null,
      user: {},
      teller_config: {},
      hasAmount: false,
      counter_config: null,
      queues: [],
      counters: [],
      counterStats: {},
      currentTicket: {
        item: null
      },
      showConfigPopup: false,
      showConfigCloseCounterPopup: false,
      showBookingPopup: false,
      showQueuePopup: false,
      showDevicesPopup: false,
      showRedirectPopup: false,
      showToastPopup: false,
      showConfirmPopup: false,
      showNotePopup: false,
      showPaymentPopup: false,
      showAppointmentPopup: false,
      showCreateCustomerPopup: false,
      appointmentType: 'new',
      toastType: 'danger',
      toastData: {
        title: '',
        details: ''
      },
      devicesType: 'displays',
      queueType: 'queue', //to show tickets queue
      status: true,
      items: [1, 2, 3, 4, 5],
      toDisplay: 1,
      queue: null,
      queueServices: [],
      queueSummary: {
        totalWaiting: null,
        servedToday: null,
        totalWaitingBooking: null,
      },
      customerStats: {
        visit_freq: "--",
        satisfaction: 0,
        consumed_service: {
          percentage: '--',
          title: '--'
        },
        pub: null
      },
      servedThisMonth: null,
      waitingList: [],
      onHoldTickets: [],
      kiosks: [],
      displays: [],
      onLine: navigator.onLine,
      sending: true,
      notifications: [],
    };
  },
  watch: {
    currentTicket: {
      handler: function (newVal, oldVal) {
        //this.sending = newVal.item !== null;
      },
      deep: true
    }
  },
  computed: {
    getStatus() {
      return localStorage.getItem('counter') !== null
    },
  },
  created() {
    // initializing variables with the actual session
    try {
      this.teller_config = JSON.parse(localStorage.getItem("user") || "{}");
      this.counter_config = JSON.parse(localStorage.getItem("counter") || "{}");
      businessService.getBusinessInformations().then((business) => {
        this.teller_config.business = business.business
        localStorage.setItem('user', JSON.stringify(this.teller_config))
      })

      this.user.name = this.teller_config.user.fname + " " + this.teller_config.user.lname;
      this.hasAmount = this.teller_config.business.ticket_price_status !== 0
    } catch (err) {
      console.log(err);
    }

    this.initPusher()

    queueService
        .getAll()
        .then((res) => {
          this.queues = res.queues.data;
        })
        .catch((err) => {
          console.log(err);
        });

    if (localStorage.getItem('counter') !== null) {
      this.getQueueData()
    }

    businessService.kiosksStatus().then((kiosks) => {
      this.kiosks = kiosks.kiosks
    })

    businessService.displaysStatus().then((displays) => {
      this.displays = displays.displays
    })

    const symbolMap = {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '0': '0'
    };
    const numberMap = {
      '١': '1',
      '٢': '2',
      '٣': '3',
      '٤': '4',
      '٥': '5',
      '٦': '6',
      '٧': '7',
      '٨': '8',
      '٩': '9',
      '٠': '0'
    }

    moment.updateLocale('ar', {
      preparse: function (string) {
        return string.replace(/\u200f/g, '').replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match) {
          return numberMap[match];
        }).replace(/،/g, ',');
      },
      postformat: function (string) {
        return string.replace(/\d/g, function (match) {
          return symbolMap[match];
        }).replace(/,/g, '،');
      },
    });
  },
  mounted() {

    if (JSON.parse(localStorage.getItem('user')).user.maintenance === 1) {
      router.push('/maintenance');
    }

    setTimeout(() => {
      businessService.kiosksStatus().then((kiosks) => {
        this.kiosks = kiosks.kiosks
      })

      businessService.displaysStatus().then((displays) => {
        this.displays = displays.displays
      })
    }, 600000)

    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  methods: {

    ...mapActions("authentication", ["logout"]),

    logout() {
      this.unsubscribeToPusher()
      store.dispatch('authentication/logout')
    },
    unsubscribeToPusher(queue_id) {
      if (queue_id) {
        this.echo.leaveChannel("private-queue-" + queue_id)
      } else {
        if (this.counter_config !== null) {
          this.echo.leaveChannel("private-queue-" + this.counter_config.queue_id)
        }

      }

    },
    openToast(type, data) {

      this.toastData = data
      this.toastType = type
      this.showToastPopup = true

    },
    payment(data) {
      queueService.updateTicketAmount(this.counter_config.queue_id, this.currentTicket.item.ticket_id, data.amount, data.type).then((res) => {
        this.openToast('success', {
          title: 'Toast.Success.title',
          details: 'Toast.Success.sub-title'
        })
        this.currentTicket.item.type_payment = data.type
        this.currentTicket.item.price = data.amount
      }).catch((err) => {
        console.log(err)
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      })
    },
    updateOnlineStatus(e) {
      const {type} = e
      this.onLine = type === 'online'
    },
    getQueueData() {
      this.counter_config = JSON.parse(localStorage.getItem("counter") || "{}");
      businessService
          .getCounterAgentStats()
          .then((res) => {
            this.counterStats = res.queue;
            // this.queueSummary.servedToday = res.queue.today;
            //this.servedThisMonth = res.queue.this_month;
          })
          .catch((err) => {
            console.log(err);
          });

      queueService
          .getByIdWithSummary(this.counter_config.queue_id)
          .then((res) => {
            this.queue = res.queue;
            this.queueServices = res.services;
            this.currentTicket.waitingTime = res.waiting_time;
            this.currentTicket.totalWaiting = res.totalWaiting;
          })

      businessService.getTodayBookingSum().then((response) => {
        this.queueSummary.totalWaitingBooking = response.total
      })
          .catch((err) => {
            console.log(err);
          });

      queueService.getTicketForCounter(this.counter_config.queue_id)
          .then((response) => {
            this.queueSummary.servedToday = response.totalServed;
            this.queueSummary.totalWaiting = response.totalWaiting;
            if (response.ticket !== null) {
              let ticket = response.ticket
              ticket.estimatedTimeToSpend *= 60;
              ticket.customer_id = ticket.id;
              this.currentTicket.item = ticket
              this.currentTicket.item.customer_id = this.currentTicket.item.id;
              this.getLastNoteForCustomer(this.counter_config.queue_id, this.currentTicket.item.customer_id)
            }
            this.sending = false;
          })
          .catch((err) => {
            console.log(err);
            this.sending = false;
          });


      /*
           queueService
               .getWaitingList(this.counter_config.queue_id)
               .then((res) => {
                 this.queueSummary.servedToday = res.totalServed;
                 this.queueSummary.totalWaiting = res.totalWaiting;

                 let firstWaitingTicket = res.waiting_list.filter((ticket) => {
                   return ticket.status === 'waiting'
                 })

                 let currentCounterTicket = res.waiting_list.filter((ticket) => {
                   return ticket.status === 'inservice' && ticket.counter_id === this.counter_config.id
                 })

                 if (currentCounterTicket.length > 0) {
                   currentCounterTicket[0].estimatedTimeToSpend *= 60;
                   currentCounterTicket[0].customer_id = currentCounterTicket[0].id;
                   this.currentTicket.item = currentCounterTicket[0]
                   this.currentTicket.item.customer_id = this.currentTicket.item.id;
                   this.getLastNoteForCustomer(this.counter_config.queue_id, this.currentTicket.item.customer_id)
                 } else if (firstWaitingTicket.length > 0) {
                   firstWaitingTicket[0].estimatedTimeToSpend *= 60;
                   firstWaitingTicket[0].customer_id = firstWaitingTicket[0].id;
                   this.currentTicket.item = firstWaitingTicket[0]
                   this.currentTicket.item.customer_id = this.currentTicket.item.id;
                   this.getLastNoteForCustomer(this.counter_config.queue_id, this.currentTicket.item.customer_id)
                 } else {
                   this.currentTicket.item = null
                   this.currentTicket.waitingTime = 0;
                   this.waitingList = []
                 }

                 this.waitingList = res.waiting_list
                 this.sending = false;
               })
               .catch((err) => {
                 console.log(err);
                 this.sending = false;
               });

     */
      queueService.getOnHoldTickets(this.counter_config.queue_id).then((res) => {
        this.onHoldTickets = res.waiting_list
      })
      this.subscribeToPusher()
    },
    getLastNoteForCustomer(queue_line_id, customer_id) {
      queueService.getLastNoteForCustomer(queue_line_id, customer_id).then((res) => {
        this.currentTicket.lastNote = res.notes
      })
    },
    configClosed(counterChange) {
      if (!counterChange) {
        this.showConfigPopup = false;
      } else {
        this.sending = true
        this.queue = true
        this.showConfigPopup = false;
        if (localStorage.getItem("counter") !== null) {
          this.counter_config = JSON.parse(localStorage.getItem("counter") || "{}");
          this.openCounter()
        } else {
          this.currentTicket.item = null
          this.currentTicket.waitingTime = 0;
          this.waitingList = []
          this.queueSummary = {
            totalWaiting: null,
            servedToday: null,
            totalWaitingBooking: null,
          }
          //this.user = {}
          this.counter_config = null
          this.queue = null
          this.onHoldTickets = []
        }
      }

    },
    openCounter() {
      let flag = false
      if (this.counter_config.queue_id !== undefined && this.counter_config.id !== undefined) {
        counterService.open(this.counter_config.queue_id, this.counter_config.id).then((data) => {
          flag = true
          this.getQueueData()
        }).catch((err) => {
          counterService.close(this.counter_config.queue_id, this.counter_config.id).then((res) => {
            counterService.open(this.counter_config.queue_id, this.counter_config.id).then(() => {
              flag = true
              if (flag) {
                this.getQueueData()
              }
            })
          })
        }).then(function () {
          flag = true
          if (flag) {
            this.getQueueData()
          }
        }).catch((err) => {
          console.log(err)
        });
      }
    },
    resetCustomerStat() {
      this.customerStats = {
        visit_freq: "--",
        satisfaction: 0,
        consumed_service: {
          percentage: '--',
          title: '--'
        },
        pub: null
      }
    },
    updateCurrentTicketData(queue) {
      if (queue !== null) {
        let firstTicket = queue.customer.customer;
        firstTicket.public_identifier = queue.customer.public_identifier
        firstTicket.ticket_id = queue.customer.id
        firstTicket.notes = queue.customer.notes
        firstTicket.customer_id = firstTicket.id
        firstTicket.service_names = queue.customer.service_names
        firstTicket.estimatedTimeToSpend = queue.customer.estimated_service_time_in_seconds
        firstTicket.status = queue.customer.status
        this.currentTicket.item = firstTicket
        this.currentTicket.waitingTime = queue.customer.expected_waiting_time

        this.getLastNoteForCustomer(this.counter_config.queue_id, this.currentTicket.item.customer_id)

      } else {
        this.currentTicket.item = null
        this.currentTicket.waitingTime = 0;
        this.waitingList = []
      }
      this.sending = false;
    },
    recallTicket() {
      queueService.displayCall(this.currentTicket.item.ticket_id).catch((err) => {
        console.log(err)
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      })
    },
    ticketAbsent() {
      if (this.currentTicket.item) {
        queueService.absent(this.counter_config.queue_id).then((res) => {
          this.updateCurrentTicketData(res)
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        })
      }
    },
    holdTicket() {
      if (this.currentTicket.item) {
        queueService.onHold(this.counter_config.queue_id, this.currentTicket.item.ticket_id).then((res) => {
          this.getQueueData()
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        }).then(() => {
          this.sending = false;
        })
      }
    },
    recallHoldTicket() {
      if (this.currentTicket.item) {
        queueService.recallOnHold(this.counter_config.queue_id, this.currentTicket.item.ticket_id).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        }).then(() => {
          this.sending = false;
        })
      }
    },
    endTicket(public_identifier, origin) {
      if (public_identifier) {
        this.sending = true;
        queueService.endTicket(this.counter_config.queue_id, public_identifier).then((res) => {
          this.openToast('success', {
            title: 'Toast.Success.title',
            details: 'Toast.Success.Delete'
          })
          if (origin !== null) {
            queueService.getOnHoldTickets(this.counter_config.queue_id).then((res) => {
              this.onHoldTickets = res.waiting_list
            })
          } else {
            this.getQueueData()
          }
        }).catch((err) => {
          console.log(err)
          this.getQueueData()
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        }).then(() => {
          this.sending = false;
        })
      }
    },

    returnOnHoldTicket(id) {
      if (id) {
        this.sending = true;
        queueService.recallOnHold(this.counter_config.queue_id, id).then((res) => {
          this.openToast('success', {
            title: 'Toast.Success.title',
            details: 'Toast.Success.sub-title'
          })
          queueService.getOnHoldTickets(this.counter_config.queue_id).then((res) => {
            this.onHoldTickets = res.waiting_list
          })
          this.getQueueData()

        }).catch((err) => {
          console.log(err)
          this.getQueueData()
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        })
      }
    },
    Redirect(queue_line) {
      let body = {
        customer_id: this.currentTicket.item.customer_id,
        queue_id: this.counter_config.queue_id,
        target_id: queue_line.id,
        keepTicket: queue_line.keepTicket ? 1 : 0
      }
      queueService.redirect(body).then((res) => {
        this.openToast('success', {
          title: 'Toast.Success.title',
          details: 'Toast.Success.Redirect'
        })
        this.getQueueData()
        location.reload();
      }).catch((err) => {
        console.log(err)
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      })
    },
    updateComment(data) {
      queueService.updateTicketNote(this.counter_config.queue_id, this.currentTicket.item.ticket_id, data.comment, data.type).then((res) => {
        this.openToast('success', {
          title: 'Toast.Success.title',
          details: 'Toast.Success.sub-title'
        })
        this.currentTicket.item.notes = data.comment
        this.currentTicket.item.type_note = data.type
      }).catch((err) => {
        console.log(err)
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      })
    },
    subscribeToPusher() {

      if (this.counter_config.queue_id !== undefined) {
        this.echo.channel("private-queue-" + this.counter_config.queue_id)
            .listen(".queue-refresh-data", (queue_data) => {
              this.toDisplay = 1;

              this.queueSummary.totalWaiting = queue_data.totalWaiting;
              this.currentTicket.waitingTime = queue_data.waiting_time;
              if (queue_data.waiting_ticket !== null) {
                if (this.currentTicket.item === null || (this.currentTicket.item.ticket_id !== queue_data.waiting_ticket.ticket_id && this.currentTicket.item.status !== "inservice")) {
                  this.currentTicket.waitingTime = queue_data.waiting_time;
                  let firstTicket = queue_data.waiting_ticket;
                  firstTicket.estimatedTimeToSpend *= 60;
                  firstTicket.customer_id = firstTicket.id
                  this.currentTicket.item = firstTicket
                  this.getLastNoteForCustomer(this.counter_config.queue_id, firstTicket.customer_id)
                }
              } else {
                if (this.currentTicket.item !== null) {
                  if (this.currentTicket.item.status !== "inservice") {
                    this.currentTicket.item = null
                  }
                }
              }
            });
      }


      this.echo
          .channel("private-teller-" + this.teller_config.user.id)
          .listen(".teller-refresh-data", (data) => {
            this.counterStats = data
          })
          .listen(".customer-stat-data", (data) => {
            this.customerStats = data
          })

      this.echo
          .channel("private-business-" + this.teller_config.user.business_id)
          .listen(".booking-created", (data) => {
            if (moment(data.booking.start_datetime).format("Y-M-D") === moment(new Date()).format("Y-M-D")) {
              this.queueSummary.totalWaitingBooking++
            }
          })

      this.echo.channel("private-teller-" + JSON.parse(localStorage.getItem('user') || '{}').user.id)
          .listen(
              ".maintenance-change",
              () => {
                businessService
                    .refresh()
                    .then(function () {
                      location.reload()
                    })
                    .catch(
                        function (err) {
                          console.log(err)
                        })
              })
    },
    swipeHandler(event) {
      if (window.innerWidth > 767) {
        return
      }
      event === 'left' ? this.toDisplay < this.items.length ? this.toDisplay++ : this.toDisplay = 1 : this.toDisplay > 1 ? this.toDisplay-- : this.toDisplay = this.items.length;
    },
    initPusher() {
      this.echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        forceTLS: false,
        wsHost: process.env.VUE_APP_WSS_URL,
        wsPort: 6001,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authEndpoint: `${ApiConfigs.broadcast_url}${ApiConfigs.pusher.auth}`,
        auth: {
          headers: {...authHeader()}
        },
      });
      // connect to pusher for queue updates
      this.subscribeToPusher()
    }
  },
};
</script>

<style scoped>

@media (max-width: 767px) {
  .footer-container {
    background: white !important;
    margin-top: 0 !important;
    height: auto !important;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    font-size: 20px !important;
  }

  .mobile-container {
    min-height: 46vh !important;
    max-height: 46vh !important;
  }

}

@media (max-height: 680px) {

}

@media (max-width: 680px) {

  .vertical-ticket.box {
    padding: unset !important;
  }

  .console.box {
    padding: unset !important;
  }

  .right-boxes {
    width: 100% !important;
  }

  .vertical-ticket-h {
    height: 100% !important;
  }
}

@media (max-height: 620px) {
  .vertical-ticket-component {
    display: none;
  }
}

.vertical-ticket-h {
  height: 92.5%;
}

.home-pointers {
  display: none !important;
}

.box {
  padding: 15px !important;
  padding-left: 30px !important;
}

.vertical-ticket {
  padding-left: unset !important;
}

.stat-container {
  padding-bottom: unset !important;
}

.right-boxes {
  padding-right: 15px !important;
}

.pointers {
  display: flex;
  margin-top: -0.5vmin;
  margin-bottom: 2vmin;
  margin-left: 2.8vmin;
}

.pointer {
  background: #444444 !important;
  opacity: 0.1 !important;
  width: 25px;
  height: 5px;
}

.active-pointer {
  opacity: 0.3 !important;
  background: #444444 !important;
}

.bottom-containers {
  padding-top: unset !important;
}

@media (max-width: 680px) {
  .home-container {
    overflow-y: auto;
  }

  .main-vue {
    height: fit-content !important;
    overflow-x: clip;
  }

  .home-pointers {
    display: flex !important;
  }

  .screen-settings {
    display: none !important;
  }

  .mobile-settings {
    display: inherit !important;
  }

  .box {
    padding-left: 15px !important;
  }

  .footer-container {
    z-index: 1;
  }
}

@media (min-height: 820px) {
  .main-vue {
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
}

/*main vue*/
.main-vue {
  width: 100%;
  height: 100% !important;
  margin: 0;
}

.footer-container {
  min-height: 7vh !important;
  max-height: 7vh !important;
  margin-top: 0;
}


/*headers*/
.right-buttons {
  max-width: 45% !important;
  display: flex;
  justify-content: left;
}

.second-header-buttons {
  margin-top: -0.5vmin;
  width: auto !important;
  display: flex;
  justify-content: left;
}

.setting-data {
  color: #ffffff !important;
}

.mobile-settings {
  display: none;
}

.setting-title {
  white-space: nowrap;
  font-family: "Exo 2", serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 3.2vmin;
  /* identical to box height */
  width: 16vh;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  /* Success Light */

  color: #93f383;
}

.setting-item {
  display: flex;
  margin-right: 5vmin;
  flex-direction: column;
}

.screen-settings {
  display: flex;
  width: 100%;
}

.settings-items-container {
  height: 77%;
}

.header-tools {
  padding-top: 0.5vmin;
  display: flex;
  margin-right: 0;
  height: 100%;
}

.icon-container {
  display: flex;
  justify-content: right;
  height: 77%;
}

.setting-icon {
  cursor: pointer;
  height: 4vmin;
  width: 4vmin;
}

.setting-icon-decoration {
  background: #00648D;
  opacity: 0.3;
  margin-top: 1.2vmin;
  height: 1vmin;
  width: 100%;
}

.logout-icon-decoration {
  background: #00648D;
  opacity: 0.6;
  margin-top: 1.2vmin;
  height: 1vmin;
  width: 100%;
}

.setting-decoration {
  opacity: 0.3;
  height: 1vmin;
  margin-top: 1vmin;
  margin-left: -5% !important;
  width: 105%;
}

.setting-container {
  width: 36vmin;
  display: flex;
  flex-direction: column;
}

.setting-icon-container {
  width: 10vmin;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.second-home-header {
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  height: 5vmin;
}

.home-header {
  display: flex;
  width: 100%;
  background: linear-gradient(127.19deg, #008fca 0.56%, #4bb0da 76.01%);
  height: 8vmin;
}

.header-user {
  margin-left: 2.5vmin;
  padding-top: 2vmin;

  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  font-size: calc(0.8 * (1vh + 1vw));
  line-height: 2vmin;
  color: #ffffff;
}

.greetings {
  font-size: calc(0.5 * (1vh + 1vw));
}

.home-container {
  overflow-y: hidden;
  overflow-x: clip;
  background: rgba(246, 246, 246, 1);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  left: 0;
  top: 0;
  width: 100%;
  height: auto !important;
}

@media (max-width: 680px) {
  .setting-icon {
    cursor: pointer;
    height: 22px !important;
  }
}
</style>
