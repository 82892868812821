<template>
  <div v-if="activeFeature">
    <div class="features-container">
      <div class="features-container-text">
        “ {{ activeFeature.text }} “
      </div>

      <div class="signature-container">
        <div class="signature">
          {{ activeFeature.signature }}<br>{{ activeFeature.position }}
        </div>
      </div>
    </div>
    <div class="pointers">
      <div v-for="feature in features" :key="feature.id" class="pointer"
           :class="{'active-pointer': feature.id === activeFeature.id}" @click="changeSlide(feature)"></div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'LbrxHomeFeatures',
  data() {
    return {
      features: [
        {
          id: 1,
          text: this.$t('LBRX_quotes.1.quote'),
          signature: this.$t('LBRX_quotes.1.writer'),
        },
        {
          id: 2,
          text: this.$t('LBRX_quotes.2.quote'),
          signature: this.$t('LBRX_quotes.2.writer'),
        }
      ],
      activeFeature: null,
    }
  },
  methods: {
    changeSlide(feature) {
      this.activeFeature = feature
    }
  },
  created() {
    this.activeFeature = this.features[0];
    setInterval(() => {
      let index = this.features.indexOf(this.activeFeature) + 1;
      if (index === this.features.length)
        index = 0;
      this.activeFeature = this.features[index];
    }, 5000)
  }
}
</script>

<style scoped>

.features-container {
  width: 88% !important;
  min-height: 240px !important;
  padding: 80px 40px 40px;
  background: linear-gradient(100.84deg, rgba(255, 255, 255, 0.183) 0%, rgba(255, 255, 255, 0.111) 100%);
  color: #FFFFFF;
}

.features-container-text {
  min-height: 150px !important;
  font-family: 'Square Peg', 'Droid Arabic Kufi', 'Exo 2' !important;
  font-style: normal;
  font-weight: 400;
  font-size: calc(1.5 * (1vh + 1vw));
  line-height: 4.4vmin;
}

.signature {
  font-family: 'Square Peg', 'Droid Arabic Kufi', 'Exo 2' !important;
  font-style: normal;
  font-weight: 400;
  font-size: calc(1 * (1vh + 1vw));
  line-height: 3.5vmin;
  color: #FFFFFF;
}

.signature-container {
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 10%;
}
</style>