<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box h-100" :class="{'right-class': $i18n.locale === 'ar'}">
        <div class="container">
          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon"
                     :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}"
                     src="../assets/icons/booking-add.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('BookingPopup.title') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('BookingPopup.sub-title') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>
        </div>
        <div class="container" style="max-height: 77%;overflow: scroll;overflow-x: hidden;">
          <div class="col">
            <span class="input-title">
                 {{ $t('BookingPopup.Client') }}
            </span>
            <div class="row">
              <div class="col-10 col-md-7" v-if="canNotCreate">
                <input id="clinetInput" :class="{'right-class': $i18n.locale === 'ar'}" v-model="client" type="text"
                       class="note-input"
                       :readonly="canNotCreate"
                       :placeholder="''">
              </div>
              <div class="col-10 col-md-7" v-else>
                <multiselect class="note-input"
                             v-model="client"
                             :filter-results="false"
                             :min-chars="1"
                             :resolve-on-load="false"
                             :delay="0.5"
                             :searchable="true"
                             :placeholder="createdCustomer"
                             :options="async function(query) { return await filterClients(query)}">
                </multiselect>
              </div>

              <div class="col-2 col-md-5">
                <lbrx-button id="createCustomerButton" icon="plus sign" class="pt-0 mt-0"
                             v-model="canNotCreate" @click="showCreateCustomerPopup= true"
                             :label="$t('BookingPopup.Create')" :icon-height="'3vmin'"
                             color="#FFFFFF" background="#008FCA" height="6vmin" width="100%"
                             font-size="0.8"></lbrx-button>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-6">
                <span class="input-title">{{ $t('BookingPopup.Department') }}</span>
                <select v-model="selected_dep" class="note-input input">
                  <option v-for="dep in departments" :id="'dep-'+dep.id" :key="dep" :value="dep.id">{{
                      dep.name
                    }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <span class="input-title">{{ $t('BookingPopup.Member') }}</span>
                <select v-model="selected_member" class="note-input">
                  <option v-for="member in members" :id="'member-'+member.id" :key="member" :value="member.id">
                    {{ member.fname + ' ' + member.lname }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row my-4">
              <div class="col-md-6">
                <lbrx-calendar-view v-on:date-changed="updateDate($event)"></lbrx-calendar-view>
              </div>
              <div class="col-md-6" style="max-height: 290px ;overflow: auto;">
                <lbrx-booking-time-picker :secondary-color="'#008FCA'" :primary-color="'#F9F9F9'" text-color="#FFFFFF"
                                          :times="times" v-model="showSlots"
                                          @timeSelected="setSlot"></lbrx-booking-time-picker>
              </div>
            </div>
            <div class="row my-4">
              <div class="col-md-6">
                <span class="input-title">{{ $t('BookingPopup.Services') }}</span>
                <multiselect class="input-title note-input"
                             mode="tags"
                             :close-on-select="false"
                             v-model="selected_services"
                             :options="services"
                             track-by="title">
                </multiselect>
              </div>
              <div class="col-md-6">
                <span class="input-title">{{ $t('BookingPopup.Queue') }}</span>
                <select v-model="selected_queue" class="note-input">
                  <option v-for="queue in queues" :key="queue" :value="queue.id">
                    {{ queue.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="popup-buttons justify-content-between">
          <lbrx-button id="confirmBookingButton" v-model="sending" icon="tick-mark-icon" :base-button="true"
                       @clicked="triggerPopupAction()"
                       :label="$t('Teller.ConfirmButton')"
                       color="#FFFFFF" background="#00CC6A" height="8vmin" width="93%"
                       font-size="0.8"></lbrx-button>
          <lbrx-button id="cancelBookingButton" v-model="close" icon="delete-red-icon" :base-button="true"
                       @clicked="closeThis()" :label="$t('Teller.Close')"
                       color="#FF5757" border="1px solid #FF5757" background="white" height="8vmin"
                       width="93%" font-size="0.9"></lbrx-button>
        </div>
      </div>
    </Transition>
  </div>
  <lbrx-add-customer-popup v-if="showCreateCustomerPopup"
                           v-model="showCreateCustomerPopup"
                           v-on:confirm-client="customerCreated($event)"
                           @close="showCreateCustomerPopup = false"></lbrx-add-customer-popup>
  <lbrx-toast v-if="showToastPopup" v-model="showToastPopup" :type="toastType" :message="toastData"
              @close="showToastPopup = false"></lbrx-toast>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxCalendarView from "@/components/LbrxCalendarView.vue";
import LbrxBookingTimePicker from "@/components/LbrxBookingTimePicker.vue";
import {businessService, queueService} from "@/_services";
import moment from "moment/moment";
import Multiselect from '@vueform/multiselect'
import LbrxAddCustomerPopup from "@/components/LbrxAddCustomerPopup.vue";
import LbrxToast from "@/components/LbrxToast.vue";

export default {
  name: 'LbrxAppointmentPopup',
  components: {LbrxToast, LbrxAddCustomerPopup, LbrxBookingTimePicker, LbrxCalendarView, LbrxButton, Multiselect},
  props: {
    modelValue: Boolean,
    current_client: Object,
    type: String
  },
  data() {
    return {
      times: [],
      sending: true,
      close: false,
      canNotCreate: false,
      showPopup: false,
      showSlots: false,
      client: '',
      date: true,
      departments: [],
      members: [],
      services: [],
      queues: [],
      customers: [],
      selected_services: null,
      selected_dep: null,
      selected_member: null,
      selected_services_for_query: "",
      selected_queue: null,
      selected_slot: null,
      selected_date: moment(new Date()).format('YYYY-MM-DD'),
      add_customer: 0,
      createdCustomer: "",
      showCreateCustomerPopup: false,
      toastType: 'danger',
      toastData: {
        title: '',
        details: ''
      },
      showToastPopup: false
    }
  },
  watch: {
    selected_dep: {
      handler: function (val) {

        let department = this.departments.filter((dep) => {
          return dep.id === val
        })[0]

        let members = []
        if (val === -1) {
          this.departments.forEach((dep) => {
            if (dep.dep_members) {
              dep.dep_members.forEach((member) => {
                members.push(member)
              })
            }
          })
        } else {
          members = department.dep_members
        }


        this.members = members.filter((member) => {
          return member.is_bookable === 1
        })

        this.services = []
        businessService.getDepServices(val).then((res) => {
          res.services.forEach((service) => {
            this.services.push({value: service.id, label: service.title})
          })
        })

        this.updateSlots()
      }
    },
    selected_date: {
      handler: function (val) {
        this.updateSlots()
      }
    },
    selected_services: {
      handler: function (val) {
        this.selected_services_for_query = val.join(',')
        queueService.getServicesQueues(this.selected_services_for_query).then((res) => {
          this.queues = res.queues
        })
      }
    },
    selected_queue: {
      handler: function (val) {
        this.sending = false
      }
    },
    selected_slot: {
      handler: function (val) {
        this.sending = false
      }
    },
  },
  methods: {

    openToast(type, data) {

      this.toastData = data
      this.toastType = type

      this.showToastPopup = true

    },

    triggerPopupAction() {
      let payload = {
        add_customer: this.add_customer,
        customer_id: this.type === 'new' ? this.client : this.current_client.id,
        department_id: this.selected_dep,
        email: "",
        fname: "",
        internal_id: "",
        lname: "",
        member_id: this.selected_member,
        no_slots: false,
        phone_number: "",
        services: this.selected_services_for_query,
        start_datetime: this.selected_date + ' ' + this.selected_slot,
        end_datetime: this.selected_date + ' ' + this.selected_slot,
        queue_target: this.selected_queue
      }

      businessService.createBooking(payload).then((res) => {
        if (res.status === "success") {
          this.openToast('success', {
            title: 'Toast.Success.title',
            details: 'Toast.Success.sub-title'
          })
          this.closeThis()
        }
      }).catch((err) => {
        console.log(err)
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      })
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close')
      }, 700)
    },
    updateDate(date) {
      this.selected_date = moment(date).format('YYYY-MM-DD')
    },
    updateSlots() {
      this.showSlots = true
      this.times = []
      businessService.getSlots(this.selected_date, this.selected_dep ? this.selected_dep : -1).then((res) => {
        this.times = res.slots
        this.showSlots = false
      }).catch((err)=>{
        this.showSlots = false
      })
    },
    setSlot(slot) {
      if (slot !== null) {
        this.selected_slot = slot.slot
      }
    },
    filterClients(query) {
      this.customers = []
      businessService.filterClients(query).then((res) => {
        res.customers.data.forEach((customer) => {
          this.customers.push({label: customer.fname + ' ' + customer.lname, value: customer.id})
        })
      })
      return this.customers
    },
    customerCreated(customer) {
      this.client = customer.id
      this.createdCustomer = customer.fname + " " + customer.lname
      this.showCreateCustomerPopup = false;
    }
  },
  created() {

    if (this.current_client && this.type === null) {
      if (this.current_client.lname === null && this.current_client.fname === null) {
        this.client = "Client-" + this.current_client.customer_id
      } else {
        this.client = this.current_client.fname + ' ' + this.current_client.lname
      }
      this.canNotCreate = true
    } else {
      this.canNotCreate = false
    }

    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });

    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  },
  mounted() {
    this.departments.push({id: -1, name: 'All'})
    let deps = JSON.parse(localStorage.getItem('user') || '{}').business.departments
    deps.forEach((dep) => {
      this.departments.push(dep)
    })
    if (this.departments.length === 2) {

      this.selected_dep = this.departments.at(1).id
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
:root {
  .multiselect-tag {
    background: #008fca !important;
  }

  .multiselect {
    min-height: 7vh;
  }

  .multiselect-tag {
    font-size: 1.7vmin !important;
    line-height: 1.5vmin;
    font-weight: 500;
  }

  .multiselect-caret {
    background: black;
    -webkit-mask-image: url('./../assets/images/arrow.png');
  }

  .multiselect-inifite-spinner, .multiselect-spinner {
    background: #008fca !important;
  }

  .multiselect-search {
    background: #FDFDFD;
    height: 95% !important;
  }
}

</style>
<style scoped>

.popup-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.note-input::placeholder {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2vmin;
  color: #E0E0E0;
}

.note-input {
  text-align: left;
  padding: 5px;
  width: 100%;
  background: #FDFDFD;
  border: 1px solid #E3E3E3;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 1.5vmin;
  height: 6vmin !important;
  color: black;
}

.input-title {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}
</style>