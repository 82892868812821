<template>
  <td class="row-item col-2" :class="index%2!==0?'even-td':''">#{{ item.public_identifier }}</td>
  <td class="row-item col-3" :class="index%2!==0?'even-td':''">
    <span v-if="item.lname !== null || item.fname !== null "
          class="user-name"> {{ item.fname ? item.fname : '-' }} {{ item.lname ? item.lname : ''}}
    </span> <br>
    <span v-if="item.internal_id != null" :style="{'color':'#FFCE00'}">{{item.internal_id}}</span>

    <span v-if="item.lname === null && item.fname === null "
          class="user-name">{{ $t('Teller.Anonyme') }}</span><br>
    <span class="client-phone">{{ item.phone_number }}</span>
  </td>
  <td v-if="type === 'archive'" class="row-item col-5" :class="index%2!==0?'even-td':''">{{ item.service_names }}</td>
  <td v-else class="row-item col-4" :class="index%2!==0?'even-td':''">{{ item.service_names }}</td>
  <td v-if="type === 'archive'" class="row-item col-2" :class="index%2!==0?'even-td':''">
    <div class="archive-status-item justify-content-end">
      <span>{{$t('Teller.Counter')}} : {{ item.counter?item.counter.name:'' }}</span>
      <img src="../assets/icons/archive-office-icon.svg"/>
    </div>
    <div class="archive-status-item justify-content-end">
      <span>{{ moment(item.statusUpdateTimestamp).locale($i18n.locale).format('H:mm') }}</span>
      <img src="../assets/icons/archive-clock-icon.svg"/>
    </div>
  </td>
  <td class="row-item col-3" v-if="type !== 'archive'" :class="index%2!==0?'even-td':''">
    <lbrx-ticket-status :status="item.status"></lbrx-ticket-status>
  </td>
</template>
<script>
import LbrxTicketStatus from "@/components/LbrxTicketStatus.vue";
import moment from "moment/moment";

export default {
  name: 'LbrxQueueListItem',
  computed: {
    moment() {
      return moment
    }
  },
  components: {LbrxTicketStatus},

  props: {
    item: Object,
    type: String,
    index:Number,
    id: String
  }
}
</script>

<style scoped>
.archive-status-container {
  margin-top: -0.7vmin;
}

.even-td{
  background:  rgb(217 217 217 / 40%);
}
.archive-status-item img {
  height: 1.5vmin;
}

.archive-status-item {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5vmin;
  line-height: 2vmin;

  /* Gray 4 */

  color: #BDBDBD;
}

.row-item {
  font-family: 'Exo 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8vmin;
  line-height: 1.8vmin;
  /* Dark Gray */
  overflow-wrap: break-word;
  color: #444444;
}

.client-phone {
  color: #8A8A8A;
}
</style>