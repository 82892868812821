import {authHeader, ApiConfigs} from '../_helpers';
import store from '@/store'

export const queueService = {
    onHold,
    recallOnHold,
    getOnHoldTickets,
    getAll,
    getAllWithSummary,
    getById,
    getByIdWithSummary,
    getWaitingList,
    getTicketForCounter,
    getInService,
    callNext,
    updateMember,
    markAsNoShow,
    redirect,
    revokeTicket,
    getTicketByUid,
    revokeTicketByUid,
    getArchivedTicketByUid,
    addDelay,
    resumeService,
    endTicket,
    displayCall,
    absent,
    addDuration,
    getArchivedTickets,
    updateTicketAmount,
    getServicesQueues,
    updateTicketNote,
    getLastNoteForCustomer
};

function getTicketForCounter(queue_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getTicketForCounter.replace(':queue_id', queue_id)}`, requestOptions).then(handleResponse);
}

function getLastNoteForCustomer(queue_id: string, customer_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getLastNoteForCustomer.replace(':queue_id', queue_id).replace(':customer_id', customer_id)}`, requestOptions).then(handleResponse);
}

function onHold(queue_id: string, ticket_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.onHold.replace(':queue_id', queue_id).replace(':ticket_id', ticket_id)}`, requestOptions).then(handleResponse);
}

function recallOnHold(queue_id: string, ticket_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.recallOnHold.replace(':queue_id', queue_id).replace(':ticket_id', ticket_id)}`, requestOptions).then(handleResponse);
}

function getOnHoldTickets(queue_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getOnHoldTickets.replace(':queue_id', queue_id)}`, requestOptions).then(handleResponse);
}

function displayCall(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.displayCall.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function resumeService(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.resumeService.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function addDelay(id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.createDelay.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function addDuration(queue_id: string, ticket_id: string, payload: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.addDuration.replace(':queue_id', queue_id).replace(':ticket_id', ticket_id) + "/addDuration"}`, requestOptions).then(handleResponse);
}


function getAll() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getAll}`, requestOptions).then(handleResponse);
}


function getAllWithSummary() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getAllWithSummary}`, requestOptions).then(handleResponse);
}


function getById(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.get.replace(':id', id)}`, requestOptions).then(handleResponse);
}


function getByIdWithSummary(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getWithSummary.replace(':id', id)}`, requestOptions).then(handleResponse);
}


function getWaitingList(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getWaitingList.replace(':id', id)}`, requestOptions).then(handleResponse);
}


function getInService(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getInService.replace(':id', id)}`, requestOptions).then(handleResponse);
}


function absent(queue_id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.absent.replace(':id', queue_id)}`, requestOptions).then(handleResponse);
}

function redirect(ticket: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(ticket)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.redirect.replace(':queue_id', ticket.queue_id)
            .replace(':customer_id', ticket.customer_id).replace(':target_id', ticket.target_id)}`
        , requestOptions).then(handleResponse);
}

function callNext(queue_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.callNext.replace(':queue_id', queue_id)}`, requestOptions)
        .then(handleResponse);
}

function endTicket(queue_id: string, public_identifier: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({queue_id: queue_id, public_identifier: public_identifier})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.endTicket}`, requestOptions)
        .then(handleResponse);
}

function updateMember(queue_id: string, customer_id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.updateMember.replace(':queue_id', queue_id).replace(':customer_id', customer_id)}`, requestOptions)
        .then(handleResponse);
}

function markAsNoShow(queue_id: string, customer_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.noshow.replace(':queue_id', queue_id).replace(':customer_id', customer_id)}`, requestOptions)
        .then(handleResponse);
}

function revokeTicket(queue_id: string, customer_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({queue_id: queue_id, customer_id: customer_id})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.revoke.replace(':queue_id', queue_id)
        .replace(':customer_id', customer_id)}`, requestOptions).then(handleResponse);
}

function getTicketByUid(uid: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_user_url + ApiConfigs.queues.getTicketByUid.replace(':uid', uid)}`,
        requestOptions).then(handleResponse);
}

function getArchivedTicketByUid(uid: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_user_url + ApiConfigs.queues.getArchivedTicketByUid.replace(':uid', uid)}`,
        requestOptions).then(handleResponse);
}

function getArchivedTickets(queue_id: string, current_page: string, per_page: string, date: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({date: date, per_page: per_page, current_page: current_page})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getArchivedTickets.replace(':queue_id', queue_id)}`,
        requestOptions).then(handleResponse);
}

function revokeTicketByUid(uid: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_user_url + ApiConfigs.queues.cancelByUid.replace(':uid', uid)}`,
        requestOptions).then(handleResponse);
}

function updateTicketAmount(queue_id: string, ticket_id: string, amount: any, type: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({price: amount, type_payment: type})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.ticketPrice.replace(':queue_id', queue_id).replace(':ticket_id', ticket_id)}`,
        requestOptions).then(handleResponse);
}

function updateTicketNote(queue_id: string, ticket_id: string, note: string, type: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({notes: note, type: type})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.ticketNote.replace(':queue_id', queue_id).replace(':ticket_id', ticket_id)}`,
        requestOptions).then(handleResponse);
}

function getServicesQueues(services: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({services: services})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.getServicesQueues}`,
        requestOptions).then(handleResponse);
}


function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                store.dispatch('authentication/logout')
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}