<template>
  <div class="welcome-container" :class="{'right-class': $i18n.locale === 'ar'}">
    <h1 class="welcome-title" :class="{'right-class': $i18n.locale === 'ar'}">
      {{ $t('welcome_interface.title') }}
    </h1>
    <div class="welcome-text" :class="{'right-class': $i18n.locale === 'ar'}">
      {{ $t('welcome_interface.details') }}
    </div>
    <br>
    <div class="features-container">
      <LbrxHomeFeatures></LbrxHomeFeatures>
    </div>
  </div>
</template>

<script>
import LbrxHomeFeatures from "@/components/LbrxHomeFeatures.vue";

export default {
  name: 'LbrxWelcomeInterface',
  components: {LbrxHomeFeatures},
}
</script>

<style scoped>

.features-container {
  margin-top: 4vh !important;
  margin-bottom: 2vh !important;
}

.welcome-text {
  font-style: normal;
  font-weight: 300;
  width: 92%;
  margin-top: 2vmin;
  font-family: "Exo 2", serif;
  font-size: 3vmin;
  line-height: 3.5vmin;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.welcome-title {
  margin-top: 2vmin;
  font-family: "Exo 2", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 5vmin;
  line-height: 4vmin;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.welcome-container {
  padding-left: 12vmin;
  padding-right: 8vmin;
  display: flex;
  flex-direction: column;
    justify-content: center;
  overflow: hidden;
  text-align: left;
  height: 100vh !important;
  background: linear-gradient(135deg, #008fca 40%, #4bb0da 80%);
}
</style>