<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box" :class="{'right-class': $i18n.locale === 'ar'}">
        <div class="container">
          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon"
                     :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}"
                     src="../assets/icons/017-copy.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('Teller.Comment') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('Teller.Comment_subtitle') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="row mx-2">
            <div class="popup-elements">
              <div class="col-12">
                <div class="popup-title-text pop-up-body-title m-0">
                  {{ $t('Teller.Comment_type') }}
                </div>
                <div v-if="types" style="margin-bottom: 3vmin" class="w-100 types-list">
                  <div class="row w-100">
                      <div v-for="(type, index) in types" :key="index" class="m-1 col-auto single-office">
                      <lbrx-radio-button :id="'noteType-'+index" @select="checkType(type)"
                                         :item="type"></lbrx-radio-button>
                      </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="popup-title-text pop-up-body-title">
                  {{ $t('Teller.Message') }}
                  <textarea id="noteMessage" :class="{'right-class': $i18n.locale === 'ar'}" v-model="comment" rows="7"
                            type="text"
                            class="note-input" :placeholder="$t('Teller.Message_texterea_placeholder')">
                  </textarea>
                </div>
              </div>


            </div>
          </div>
          <div class="row mx-1">
            <div class="col-6">
              <lbrx-button v-model="sending" icon="tick-mark-icon" :base-button="true" @clicked="triggerPopupAction()"
                           :label="$t('Teller.ConfirmButton')"
                           color="#FFFFFF" background="#00CC6A" height="8vmin" width="97%"
                           font-size="0.9"></lbrx-button>
            </div>

            <div class="col-6">
              <lbrx-button v-model="close" icon="delete-red-icon" :base-button="true" @clicked="closeThis()"
                           :label="$t('Teller.Close')"
                           color="#FF5757" border="1px solid #FF5757" background="white" height="8vmin" width="97%"
                           font-size="0.9"></lbrx-button>
            </div>


          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxRadioButton from "@/components/LbrxRadioButton.vue";
import {businessService} from "@/_services";

export default {
  name: 'LbrxNotePopup',
  components: {LbrxRadioButton, LbrxButton},
  props: {
    modelValue: Boolean,
    note: String,
    type: String
  },
  data() {
    return {
      sending: false,
      close: false,
      showPopup: false,
      types: [],
      selected_type: null,
      comment: ""
    }
  },
  mounted() {
    businessService.getNoteTypes().then((res) => {
      this.types = res.types;
      if (this.type === null) {
        if (this.types.length > 0) {
          this.selected_type = this.types.at(0);
          this.types.at(0).selected = true;
        }
      } else {
        const results = this.types.filter(obj => {
          return obj.id === this.type.id;
        });
        if (results.length > 0) {
          this.selected_type = results.at(0);
          results.at(0).selected = true;
        }
      }

    })
  },
  methods: {
    triggerPopupAction() {
      this.$emit('confirm-note', {
        comment: this.comment,
        type: this.selected_type !== null ? this.selected_type.id : null
      })
      this.closeThis()
    },
    checkType(type) {
      this.types.forEach((type) => type.selected = false);
      type.selected = true;
      this.selected_type = type
      this.sending = false
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close')
      }, 700)
    },
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });

    if (this.note) {
      this.comment = this.note
    }

    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  }
}
</script>

<style scoped>
.popup-elements {
  overflow-x: hidden;
  height: 100%;
  padding: 4px;
}

.types-list {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.confirm-popup-body {
  height: 100%;
  display: flex;

  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;

  color: #8A8A8A;
}

.popup-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.note-input::placeholder {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vmin;
  line-height: 2.5vmin;

  /* Gray 5 */

  color: #E0E0E0;
}

.note-input {
  text-align: left;
  resize: none;
  padding: 20px;
  min-height: 40vmin;
  height: 100%;
  width: 100%;
  background: #FDFDFD;
  border: 1px solid #E3E3E3;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vmin;
  line-height: 2.5vmin;

  color: black;
}
</style>