<template>
  <div class="statistics-box">
    <div :class="{'right-class': $i18n.locale === 'ar'}" class="statistics-title">{{ $t('Teller.Stat_Perf') }}</div>
    <div v-if="modelValue.total_amount_today || modelValue.servedToday || modelValue.served_this_month"
         class="statistics-container">
      <div class="statistics">
        <div class="stat-tag" :class="{'right-class': $i18n.locale === 'ar'}">{{ $t('Teller.ServedToday') }}</div>
        <div id="servedToday" class="stat-value" :class="{'right-class': $i18n.locale === 'ar'}">{{ modelValue.served_today || '-' }}</div>
        <div class="stat-graphs">

        </div>
      </div>
      <div class="statistics">
        <div class="stat-tag" :class="{'right-class': $i18n.locale === 'ar'}">{{ $t('Teller.ServedMonth') }}</div>
        <div id="servedThisMonth" class="stat-value" :class="{'right-class': $i18n.locale === 'ar'}">{{ modelValue.served_this_month || '-' }}</div>
        <div class="stat-graphs">
        </div>
      </div>
      <div v-if="hasAmount" class="statistics">
        <div class="stat-tag" :class="{'right-class': $i18n.locale === 'ar'}">{{ $t('Teller.Total') }}</div>
        <div id="totalAmountToday" class="stat-value" :class="{'right-class': $i18n.locale === 'ar'}">{{ modelValue.total_amount_today || '-' }}</div>
        <div class="stat-graphs">
        </div>
      </div>
    </div>
    <div class="no-data-container" v-else>
      <div class="statistics-image-container">
        <img src="../assets/images/statistics-image.png"/>
      </div>
      <div class="no-data-label">{{ $t('Teller.No_stat') }}</div>
    </div>
    <div class="pointers">
      <div v-for="item in items" :key="item" class="pointer" :class="{'active-pointer': item === 1}"></div>
    </div>
  </div>
</template>
<script>
import moment from "moment/moment";

export default {
  name: 'LbrxStatisticsBox',
  props: {
    modelValue: Object,
    hasAmount: Boolean
  },
  data() {
    return {
      items: [1, 2, 3, 4, 5]
    }
  },
  computed: {},
  methods: {
    getTimeValue(time, format) {
      const t = moment.utc(time * 1000).format(format);
      if (t !== "00") {
        return t;
      }
      return null;
    }
  }
}
</script>
<style scoped>
@media (max-width: 680px) {
  .statistics-box {
    height: 38vh !important;
  }
}
.stat-graphs {
  margin-top: 2vmin;
  height: 100%;
}

.stat-value {
  font-family: 'Exo 2', serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 3.5vmin;
  margin-top: 3vmin;
  /* identical to box height, or 106% */


  /* Light/Base/Chart Title */

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.stat-tag {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2.2vmin;
  /* identical to box height, or 143% */

  letter-spacing: 0.1vmin;

  /* Light/Base/Chart Title */

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.pointers {
  margin-top: 1.5vmin;
}

.pointer {
  background: #444444 !important;
  opacity: 0.1 !important;
  width: 25px;
  height: 5px;
}

.active-pointer {
  opacity: 0.3 !important;
  background: #444444 !important;
}

.statistics {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 15px;
}

.statistics-image-container img {
  height: 8vmin;
}

.statistics-image-container {
  display: flex;
  justify-content: center;
  margin-top: 5vmin;
}

.no-data-label {
  text-align: center;
  margin-top: 1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.4vmin;
  line-height: 4vmin;

  /* Gray Light */

  color: #E3E3E3;
}

.no-data-container {
  height: 60%;
}

.statistics-container {
  height: 75%;
  display: flex;
  margin: auto;
  background: #FFFFFF;
  /* Light/Elevation */

  box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039), 0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
}

.statistics-title {
  font-family: 'Exo 2', serif;
  font-style: normal;
  margin-bottom: 1vmin;
  min-width: 35vmin !important;
  font-weight: 400 !important;
  font-size: 2.4vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.statistics-box {
  height: 100%;
  padding: 20px;
  background: white;
}
</style>