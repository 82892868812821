<template>
  <div class="button-container">
    <button :disabled="modelValue" class="lbrx-button" @click="$emit('clicked')"
      :style="{ 'border': border ? border : 'none', 'opacity': modelValue ? '0.6' : '1', 'display': small ? 'flex' : '', 'flex-direction': small ? 'column' : '', 'background': background, 'color': color, 'height': height, 'width': width, 'font-size': 'calc(' + fontSize + '*(1vh + 1vw))' }">
      <div style="display: flex; position: relative;" v-if="!small">
        <div style="position: relative;
          left: 24%;
          display: flex;
        ">
          <div style="margin-right: 3vmin" v-if="icon">
            <img class="icon-style" :style="{ 'height': iconHeight ? iconHeight : '3vmin' }"
              :class="{ 'right-icon': $i18n.locale === 'ar', 'left-icon': $i18n.locale !== 'ar' }" :src="getIcon()" />
          </div>
          <div style="margin-top: auto;margin-bottom: auto">
            <span>{{ label }}</span>
          </div>
        </div>
      </div>
      <div style="height: 100%;width: 100%" v-else>
        <div v-if="label && icon"
          style="display: flex;flex-direction:column;justify-content: space-between;width: 100%;height: 100%">
          <div style="display:flex;width: 100%;justify-content: center;margin-top: 0.5vmin" v-if="icon">
            <img class="icon-style" :style="{ 'height': iconHeight ? iconHeight : '5vmin' }"
              :class="{ 'right-icon': $i18n.locale === 'ar', 'left-icon': $i18n.locale !== 'ar' }" :src="getIcon()" />
          </div>
          <div style="margin-bottom: 1vh !important;" :style="{ 'font-size': fontSize + 'vmin' }">
            <span>{{ label }}</span>
          </div>
        </div>
        <div style="height: 100% !important;width: 100%;display: flex" v-else-if="!label && icon">
          <img style="margin: auto" :style="{ 'height': iconHeight ? iconHeight : '7vmin' }"
            :class="{ 'right-icon': $i18n.locale === 'ar', 'left-icon': $i18n.locale !== 'ar' }" :src="getIcon()" />

        </div>
        <div style="height: 100%;width: 100%;display: flex" v-else>
          <div :style="{ 'font-size': fontSize + 'vmin' }" style="margin-top: auto;margin-bottom: auto">
            <span>{{ label }}</span>
          </div>
        </div>
      </div>
    </button>
  </div>
</template>
<script>
export default {
  name: 'LbrxButtonIcon',
  props: {
    fontSize: {
      required: true,
      value: String
    },
    border: {
      required: false,
      value: String
    },
    height: {
      required: true,
      value: String
    },
    width: {
      required: true,
      value: String
    },
    background: {
      required: true,
      value: String
    },
    color: {
      required: true,
      value: String
    },
    label: {
      required: false,
      value: String
    },
    icon: {
      required: false,
      value: String
    },
    small: Boolean,
    iconHeight: String,
    baseButton: Boolean,
    modelValue: Boolean,
  },
  emits: ['clicked'],
  methods: {
    getIcon() {
      if (this.icon) {
        var images = require.context('../assets/')
        return images('./icons/' + this.icon + ".svg")
      }
    },
  },
  computed: {
    floatIcon() {
      if (!this.baseButton) {
        if (this.$i18n.locale === 'ar') {
          return 'right'
        }
        return 'left'
      }
      return 'none'
    }
  }
}
</script>

<style scoped>
.lbrx-button {
  font-family: 'Exo 2', serif;
  font-style: normal;
  line-height: 4vmin;
  justify-content: left !important;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
}

.icon-style {
  height: 5vmin;
  transform: translate(0, -50%);
  position: relative;
  top: 50%;
}

.button-container {

  margin-top: 2vmin;
  margin-bottom: 2vmin;
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.left-icon {
  float: v-bind(floatIcon);
}

.right-icon {
  float: v-bind(floatIcon);
  margin-left: 3vmin;
}

@media (max-width: 822px) {
  .button-container {
    margin: 0 !important;
  }
}
</style>