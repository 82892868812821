<template>
  <VueDatePicker :locale="$i18n.locale" v-model="date" inline auto-apply :min-date="new Date()"
                 :enable-time-picker="false"></VueDatePicker>
</template>
<script>

export default {
  name: 'LbrxCalendarView',
  props: {
    modelValue: Date,
  },
  data() {
    return {
      date: null
    }
  },
  watch: {
    date: {
      handler: function (val) {
        this.$emit('date-changed', val)
      }
    }
  },
  computed:{
    getRotation() {
      if(this.$i18n.locale === 'ar') {
        return "rotate(180deg)";
      }
      return "rotate(0deg)";
    }
  }
}
</script>

<style>
:root {
  .dp__menu_inner {
    background: #F9F9F9;
    font-family: "Exo 2", sans-serif;
  }

  .dp__cell_inner {
    color: #9C9C9C;
  }

  .dp__cell_disabled {
    color: rgba(156, 156, 156, 0.4) !important;
  }

  .dp__cell_offset {
    color: rgba(156, 156, 156, 0.4) !important;
  }

  .dp__active_date {
    color: white !important;
    background: #008fca !important;
  }

  .dp__calendar_header_item {
    color: #008fca;
  }

  .dp__btn, .dp__month_year_select {
    color: #008fca;
  }

  .dp__inner_nav {
    background: #008fca;
    color: white;
    border-radius: unset;
  }

  .dp__outer_menu_wrap {
    width: inherit;
  }

  .dp__inner_nav svg {
    transform: v-bind(getRotation) !important;
  }
}
</style>