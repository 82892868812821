<template>
  <div class="ticket-on-hold-container">
    <div :class="{'right-class': $i18n.locale === 'ar'}" class="ticket-on-hold-title">
      {{ $t('Teller.Waiting_Tickets') }}
    </div>
    <div v-if="teller_config.business.myLiberrex.queue_on_hold !== 1" class="no-data-container justify-content-around"
         :class="{'right-class': $i18n.locale === 'ar'}">
      <div class="statistics-image-container">
        <img src="../assets/images/no-ticket-data.png"/>
      </div>
      <div class="no-data-label">{{ $t('Teller.no_service') }}</div>
    </div>
    <div v-else-if="waiting_list.length !== 0" class="container tickets-on-hold"
         :class="{'right-class': $i18n.locale === 'ar'}">
      <div class="row">
        <div class="col-4" :id="'onHold-'+item.public_identifier" v-for="item in waiting_list.slice(0,3)" :key="item">
          <div class="ticket-on-hold justify-content-between">
            <span class="ticket-value"
                  :style="'font-size:'+ fixFontSize(item.public_identifier.replace(' ',''))+ ' !important; line-height: '+fixLineHeight(item.public_identifier.replace(' ',''))+' !important; ' ">{{
                item.public_identifier.replace(' ', '')
              }}</span>
            <div class="ticket-buttons">
              <img id="endOnHoldTicket" class="ticket-button" src="../assets/icons/clear-button-icon.svg"
                   alt="Delete Ticket"
                   @click="endTicket(item.public_identifier)"/>
              <img id="recallOnHoldTicket" class="ticket-button" src="../assets/icons/eject-button-icon.svg"
                   alt="Recall Ticket"
                   @click="recallHoldTicket(item.ticket_id)"/>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="no-data-container justify-content-around" v-else :class="{'right-class': $i18n.locale === 'ar'}">
      <div class="statistics-image-container">
        <img src="../assets/images/no-ticket-data.png"/>
      </div>
      <div class="no-data-label">{{ $t('Teller.No_ticket') }}</div>
    </div>
  </div>
</template>
<script>
import {queueService} from "@/_services";

export default {
  name: 'LbrxTicketOnHold',
  props: {
    waiting_list: Array,
    modelValue: Object,
  },
  data() {
    return {
      teller_config: this.teller_config = JSON.parse(localStorage.getItem("user") || "{}")
    }
  },
  methods: {
    recallHoldTicket(id) {
      this.$emit('returnOnHoldTicket', id)
      // queueService.recallOnHold(this.modelValue.queue_id, id)
    },
    endTicket(public_identifier) {
      this.$emit('endTicket', public_identifier)
    },
    fixFontSize(public_identifier) {
      if (public_identifier.length > 3) {
        return '2.5vmin'
      }
      return '3.7vmin'
    },
    fixLineHeight(public_identifier) {
      if (public_identifier.length > 3) {
        return '2.9vmin'
      }
      return '5.4vmin'
    },
  }

}
</script>

<style scoped>
.ticket-value {
  margin-top: 0.5vmin;
  width: 100%;
  text-align: center;

  margin-left: -0.7vmin;
  font-family: 'Digital Numbers', serif !important;
  font-style: normal;
  font-weight: 400;

  color: #FFFFFF;
}

.ticket-button {
  cursor: pointer;
  height: 3vmin;
  margin-top: -0.3vmin;
  margin-bottom: 1vmin;
}

.ticket-buttons {
  height: 100%;
  padding: 0.1vmin;
  display: flex;
  flex-direction: column;
}

.tickets-on-hold {
  margin-left: 15px;
  margin-top: 15px
}

.ticket-on-hold {
  padding: 1vmin;
  width: 16vmin;
  height: 9vmin;
  background: #FFCE00;
  display: flex;
}

.ticket-on-hold-container {
  width: 100% !important;
  height: 100% !important;
  min-height: 21vh !important;
  padding: 20px;
  background: white;
}

.ticket-on-hold-title {
  font-family: 'Exo 2', serif;
  font-style: normal;
  margin-bottom: 1vmin;
  min-width: 40vmin !important;

  font-weight: 400 !important;
  font-size: 2.4vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}


.no-data-container {
  width: 100%;
  height: 10vmin;
  margin-top: 2vmin;
  display: flex;
  justify-content: center;
}

.statistics-image-container img {
  height: 9vmin;
}

.statistics-image-container {
  width: 50%;
  display: flex;
  justify-content: center;
}

.no-data-label {
  width: 100%;
  /*  text-align: left !important;*/
  margin-top: 1.6vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.2vmin;
  line-height: 4vmin;

  /* Gray Light */

  color: #E3E3E3;
}
</style>