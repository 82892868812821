<template>


  <Transition name="fade">
    <div v-if="modelValue" class="toast-container">
      <div v-if="showPopup" class="popup-box">
        <div class="container d-flex flex-column h-100">

          <div class="row">
            <div class="col-2">
              <img class="mt-4" :src="getIcon()" width="50"></div>
            <div class="col-9">
              <div class="my-4">
                <div class="toast-title">
                  {{ $t(message.title) }}
                </div>
                <div class="toast-msg">
                  {{ $t(message.details) }}
                </div>
              </div>

            </div>
            <div class="col-1">
              <div @click="close()" class="close-popup">
                X
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Transition>
</template>

<script>

export default {
  name: 'LbrxToast',
  components: {},
  props: {
    modelValue: Boolean,
    message: {type: Object, required: true},
    type: {type: String, required: true}
  },
  data() {
    return {
      showPopup: false,
      icon: '',

    }
  },
  created() {
    this.showPopup = true;
    setTimeout(() => {
      this.close()
    }, 5000)
  },
  methods: {
    close() {
      this.showPopup = false;
      this.$emit('close')
    },
    getIcon() {
      let images = require.context('../assets/')
      if (this.type === 'danger') {
        return images('./icons/delete-button-icon.svg')
      }
      if (this.type === 'warning') {
        return images('./icons/info-white.svg')
      }
      return images('./icons/tick-mark-icon.svg')
    }
  },
  computed: {
    setToastcolor() {
      if (this.type === 'danger') {
        return 'rgb(255, 87, 87)'
      }
      if (this.type === 'warning') {
        return 'rgb(255, 206, 0)'
      }
      return 'rgb(0, 204, 106)'
    },
    bottomPosition() {
      return '10px'
    }
  }
}
</script>

<style scoped>

.toast-container {
  z-index: 2147483647;
  position: absolute;
  width: 450px;
  height: 100px;
  right: 10px;
  bottom: v-bind(bottomPosition);
  background: v-bind(setToastcolor) !important;
  overflow-y: hidden;
  overflow-x: hidden;
}

.popup-box {
  background: v-bind(setToastcolor);
  width: unset;
  padding: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.close-popup {
  font-size: 3.0vmin;
  margin-top: -10px;
}

.toast-title {
  font-family: "Exo 2", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4vmin;
  line-height: 3.2vmin;
  color: white;
}

.toast-msg {
  font-family: "Exo 2", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.5vmin;
  line-height: 2.5vmin;
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>