<template>
  <div class="login-container">
    <div class="row">
      <div class="col-md-8 d-none d-md-block">
        <lbrx-welcome-interface></lbrx-welcome-interface>
      </div>
      <div class="col-md-4 col-12 justify-content-center" style="overflow-y: clip">
        <lbrx-login-logo></lbrx-login-logo>
        <div class="login-form-container">
          <div style="width: 70%;">
            <div style="height: 9vh;" class="my-2">
              <div v-if="errorLogin" class="error-container">
                <lbrx-error-notification :label="errors[errors.length-1]" color="#FF5757"
                                         background="rgba(255, 158, 152, 0.5)" height="8" width="100%" font-size="0.7"
                                         icon="error-icon"></lbrx-error-notification>
              </div>
            </div>

            <div class="form-container">
              <lbrx-input type="email" icon="email-icon" :label="$t('Auth.Email')" :model-value="email"
                          v-model="email" :has_error="errors.length>0"></lbrx-input>
              <lbrx-input type="password" icon="password-icon" :label="$t('Auth.Password')" :model-value="password"
                          v-model="password" :has_error="errors.length>0"></lbrx-input>


              <lbrx-checkbox :model-value="rememberMe" v-model="rememberMe"
                             :label="$t('Auth.Remember')"></lbrx-checkbox>


              <lbrx-button v-model="sending" icon="login-icon" @clicked="handleSubmit()" :label="$t('Auth.Login')"
                           :base-button="true"
                           color="#FFFFFF" background="#008FCA" height="6vmin" width="100%"
                           font-size="1.1"></lbrx-button>
            </div>
          </div>
        </div>
        <div class="login-footer">
          <div class="footer-container">
            <div class="footer-buttons">
              <lbrx-footer-button icon="language-icon" @clicked="showLanguageSelect = true" :label="language"
                                  color="#8A8A8A" background="white" height="4.5vmin" width="20vmin" font-size="0.7"
                                  border="#F5F5F5"></lbrx-footer-button>
              <lbrx-footer-button icon="assistance-icon" @clicked="showAssistance = true" :label="$t('Teller.NeedHelp')"
                                  color="#8A8A8A" background="white" height="4.5vmin" width="20vmin" font-size="0.7"
                                  border="#F5F5F5"></lbrx-footer-button>
            </div>
            <div class="copyright">
              Liberrex.com © Copyright {{ new Date().getFullYear() }}
            </div>
          </div>
          <lbrx-language-select-popup v-if="showLanguageSelect" @close="showLanguageSelect = false"
                                      v-model="showLanguageSelect"></lbrx-language-select-popup>
          <lbrx-technicial-assistance-popup v-if="showAssistance" @close="showAssistance = false"
                                            v-model="showAssistance"></lbrx-technicial-assistance-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LbrxInput from "@/components/LbrxInput.vue";
import LbrxCheckbox from "@/components/LbrxCheckbox.vue";
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxFooterButton from "@/components/LbrxFooterButton.vue";
import LbrxErrorNotification from "@/components/LbrxErrorNotification.vue";
import {mapActions} from "vuex";
import LbrxLanguageSelectPopup from "@/components/LbrxLanguageSelectPopup.vue";
import LbrxTechnicialAssistancePopup from "@/components/LbrxTechnicialAssistancePopup.vue";
import LbrxWelcomeInterface from "@/components/LbrxWelcomeInterface.vue";
import LbrxLoginLogo from "@/components/LbrxLoginLogo.vue";

export default {
  name: "LoginPageNoRecaptcha",
  components: {
    LbrxLoginLogo,
    LbrxWelcomeInterface,
    LbrxTechnicialAssistancePopup,
    LbrxLanguageSelectPopup,
    LbrxErrorNotification,
    LbrxFooterButton,
    LbrxButton,
    LbrxCheckbox,
    LbrxInput
  },
  data() {
    return {
      showLanguageSelect: false,
      showAssistance: false,
      errorLogin: false,
      sending: false,
      email: '',
      password: '',
      rememberMe: false,
      errors: [],
      language: ''
    }
  },
  mounted() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Enter' ? this.handleSubmit() : null;
    });
    let lang = localStorage.getItem('Language')
    if (lang === 'fr') {
      this.language = 'Français'
    }
    if (lang === 'ar') {
      this.language = 'العربية'
    }
    if (lang === 'en') {
      this.language = 'English'
    }
  },
  methods: {
    ...mapActions("authentication", ["loginNoRecaptcha"]),

    validEmail(email) {
      let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm() {
      this.errors = [];

      if (!this.email.length) {
        this.errors.push("email required.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.");
      }
      if (!this.password.length) {
        this.errors.push("password required.");
      }
      if (!this.errors.length) {
        return true;
      }
      this.errorLogin = true;
      this.sending = false;
    },
    handleSubmit() {
      this.errorLogin = false;
      this.sending = true;
      if (this.checkForm()) {
        this.errorLogin = false;
        const {email, password, recaptcha} = this;
        this.loginNoRecaptcha({email, password})
            .then(() => {
              this.sending = false;
            })
            .catch(() => {
              this.sending = false;
              this.errors.push(this.$t('Teller.Login_error'));
              this.errorLogin = true;
            });
      }
    }
  }
}
</script>

<style scoped>
.input-style {
  height: 7vh!important;
  width: 90%;
}

.error-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2vmin;
}

.footer-container {
  position: absolute;
  bottom: 0px !important;
}

.login-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-form-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: auto;
}

.login-container {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: auto !important;
  overflow-x: hidden;
}

.copyright {
  line-height: 25px !important;
}

.button-container {
  height: unset !important;
}

</style>
