<template>
  <div id="loader" v-if="(sending)"
       :style="{'background':(sending)?'rgba(255,255,255,0.87)':'unset'}"
       style="position: absolute;width: 100%;height: 100%;z-index: 10001522283">
    <div id="secondLoader" v-if="(sending)" class="spinner-border" :style="{'color': '#008fca'}"
         style="margin: auto;height: 9vmin;width: 9vmin;font-size: 3vmin;position: absolute; top: 50%; right: 50%;"
         role="status">
    </div>
  </div>

  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box">
        <div :class="{'right-class': $i18n.locale === 'ar'}" class="container d-flex flex-column h-100">

          <div class="row flex-grow-0">

            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon"
                     :style="{'margin-left': $i18n.locale === 'ar' ? '2vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '2vmin'}"
                     v-if="type === 'archive'" src="../assets/icons/folder-icon.svg" alt=""/>
                <img class="popup-title-icon"
                     :style="{'margin-left': $i18n.locale === 'ar' ? '2vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '2vmin'}"
                     v-else src="../assets/icons/waiting-queue-icon.svg" alt=""/>
                <div class="popup-title-text">
                  <span v-if="type === 'archive'"> {{ $t('Teller.Archive') }} </span> {{ $t('Teller.queue') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ type === 'archive' ? $t('Teller.archive_subtitle') : $t('Teller.queue_subtitle') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>
          <div v-if="type === 'archive'" class="date-office-container justify-content-between">
            <div class="input-container">
                <lbrx-input type="text" id="startDate" icon="calendar-grey-icon" :label="$t('Teller.StartDate')" v-model="date" :no_margin="true"
                            @click="showCalendar = true"></lbrx-input>
                <lbrx-simple-calendar-view v-if="showCalendar" v-on:date-changed="updateDate($event)" style="position: absolute;
    width: fit-content;"></lbrx-simple-calendar-view>

            </div>
          </div>
          <div class="list-paginate-container">
            <div class="row">
              <div v-if="!loading" class="popup-body">
                <div v-if="type === 'archive'" class="table-headers container">
                  <div class="row">
                    <div class="col-2">
                      <div class="signle-header">{{ $t('Teller.N°') }}</div>
                    </div>
                    <div class="col-3">
                      <div class="signle-header">{{ $t('Teller.CustomerLabel') }}</div>
                    </div>

                    <div class="col-5">
                      <div class="signle-header">{{ $t('Teller.ServiceLabel') }}</div>
                    </div>
                    <div class="col-2">
                      <div class="signle-header">{{ $t('Teller.Status') }}</div>
                    </div>
                  </div>
                </div>
                <div v-else class="table-headers container">
                  <div class="row">
                    <div class="col-2">
                      <div class="signle-header">{{ $t('Teller.N°') }}</div>
                    </div>
                    <div class="col-3">
                      <div class="signle-header">{{ $t('Teller.CustomerLabel') }}</div>
                    </div>
                    <div class="col-4">
                      <div class="signle-header">{{ $t('Teller.ServiceLabel') }}</div>
                    </div>
                    <div class="col-3">
                      <div class="signle-header">{{ $t('Teller.Status') }}</div>
                    </div>
                  </div>
                </div>
                <table class="table" v-if="queues.length > 0">
                  <tbody>
                  <tr v-for="(queue, index) in displayedQueues" :key="index">
                    <lbrx-queue-list-item :item="queue" :type="type" :id="'queue-'+queue.id" :index="index"></lbrx-queue-list-item>
                  </tr>
                  </tbody>
                </table>
                <div class="no-data-container" v-else>
                  <div class="statistics-image-container">
                    <img src="../assets/images/no-ticket-data.png"/>
                  </div>
                  <div class="no-data-label">{{ $t('Teller.No_stat') }}</div>
                </div>
              </div>
            </div>
            <div class="pagination-container">
              <lbrx-pagination-numbers @next-page="nextPage" @previous-page="previousPage" @specific-page="specificPage"
                                       v-model="activePage" :pages="pagesNumber"></lbrx-pagination-numbers>
            </div>
          </div>

        </div>

      </div>

    </Transition>

  </div>
</template>

<script>

import LbrxQueueListItem from "@/components/LbrxQueueListItem.vue";
import LbrxPaginationNumbers from "@/components/LbrxPaginationNumbers.vue";
import {businessService, queueService} from "@/_services";
import moment from "moment/moment";
import LbrxInput from "@/components/LbrxInput.vue";
import LbrxSimpleCalendarView from "@/components/LbrxSimpleCalendarView.vue";

export default {
  name: 'LbrxQueuePopup',
  components: {LbrxSimpleCalendarView, LbrxInput, LbrxPaginationNumbers, LbrxQueueListItem},
  props: {
    modelValue: Boolean,
    type: String,
    queue: Object
  },
  data() {
    return {
      activePage: 1,
      pagesNumber: 3,
      perPage: 8,
      date: moment(new Date()).format('YYYY-MM-DD'),
      offices: [],
      queues: [],
      displayedQueues: [],
      sending: false,
      showPopup: false,
      loading: true,
      showCalendar: false
    }
  },
  watch: {
    date: function () {
      this.activePage = 1
      this.sending=true;
      queueService.getArchivedTickets(this.queue.id, this.activePage, 8, this.date).then((res) => {
        this.queues = res.waiting_list.data
        this.pagesNumber = res.waiting_list.last_page
        this.activePage = res.waiting_list.current_page
        this.displayedQueues = this.queues.slice(0, this.perPage)
        this.sending = false;
      })
          .catch((err) => {
            console.log(err);
          });
    }
  },
  mounted() {
    this.sending=true;
    if (this.type === 'archive') {
      queueService.getArchivedTickets(this.queue.id, this.activePage, 8, this.date).then((res) => {
        this.queues = res.waiting_list.data
        this.pagesNumber = res.waiting_list.last_page
        this.activePage = res.waiting_list.current_page
        this.displayedQueues = this.queues.slice(0, this.perPage)
        this.sending = false;
      })
          .catch((err) => {
            console.log(err);
          });
    } else {
      queueService
          .getWaitingList(this.queue.id)
          .then((res) => {
            this.queues = res.waiting_list;
            this.pagesNumber = Math.round(this.queues.length / this.perPage)
            this.displayedQueues = this.queues.slice(0, this.perPage)
            this.sending = false;
          })
          .catch((err) => {
            console.log(err);
          });
    }

  },
  methods: {
    updateDate(date) {
      this.date = moment(date).format('YYYY-MM-DD')
      this.showCalendar = false;
    },
    nextPage() {
      if (this.activePage + 1 > this.pagesNumber)
        return;

      if (this.type === 'archive') {
        this.sending = true;
        queueService.getArchivedTickets(this.queue.id, this.activePage + 1, 8, this.date).then((res) => {
          this.queues = res.waiting_list.data
          this.pagesNumber = res.waiting_list.last_page
          this.activePage = res.waiting_list.current_page
          this.displayedQueues = this.queues.slice(0, this.perPage)
          this.sending = false;
        })
            .catch((err) => {
              console.log(err);
            });
      } else {
        this.displayedQueues = this.queues.slice(this.activePage * this.perPage, (this.activePage * this.perPage) + this.perPage)
      }
      this.activePage++;
    },
    previousPage() {
      if (this.activePage - 1 < 1)
        return;

      if (this.type === 'archive') {
        this.sending = true;
        queueService.getArchivedTickets(this.queue.id, this.activePage - 1, 8, this.date).then((res) => {
          this.queues = res.waiting_list.data
          this.pagesNumber = res.waiting_list.last_page
          this.activePage = res.waiting_list.current_page
          this.displayedQueues = this.queues.slice(0, this.perPage)
          this.sending = false;
        })
            .catch((err) => {
              console.log(err);
            });
      } else {
        this.displayedQueues = this.queues.slice(this.activePage / this.perPage, (this.activePage / this.perPage) - this.perPage)
      }
      this.activePage--;
    },
    specificPage(page) {
      if (this.activePage !== page) {
       
        this.activePage = page;
        this.sending = true;
        if (this.type === 'archive') {
          queueService.getArchivedTickets(this.queue.id, this.activePage, 8, this.date).then((res) => {
            this.queues = res.waiting_list.data
            this.pagesNumber = res.waiting_list.last_page
            this.activePage = res.waiting_list.current_page
            this.displayedQueues = this.queues.slice(0, this.perPage)
            this.sending = false;
          })
              .catch((err) => {
                console.log(err);
              });
        } else {
          //this.displayedQueues = this.queues.slice((this.activePage - 1) * this.perPage, ((this.activePage - 1) * this.perPage) + this.perPage)

          this.displayedQueues = this.queues.slice(
            (this.activePage - 1) * this.perPage, 
            (this.activePage - 1) * this.perPage + this.perPage
          );
          this.sending = false;

        }
      }
    },
    triggerPopupAction() {
      this.closeThis();
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close');
      }, 700)
    },
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });
    this.queues.forEach((queue) => {
      queue.office = this.offices[0];
    })
    this.queues = this.waitingList
    this.loading = false;
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  },
  computed: {
    FixBoxWidth() {
      if (this.type === 'archive') {
        return '45%'
      }
      return '45%'
    }
  }
}
</script>


<style scoped>


.input-container {
  margin-right: 0.5vmin;
  width: 45%;
}

tr {
  border-bottom: 1.5px solid #E3E3E3
}

.input-container-archive {
  margin-left: 0.5vmin;
  margin-right: 0.5vmin;
  width: 65%;
}

.list-paginate-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.input-label {
  margin-left: 0.5vmin;

  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.6vmin;
  line-height: 2.6vmin;
  /* identical to box height */


  /* Medium Gray */

  color: #8A8A8A;
}

.date-office-container {
  display: flex;
  margin-top: 2vmin;
  width: 100%;
}

.signle-header {
  margin-bottom: 1vmin;
  padding-bottom: 0.8vmin;
  border-bottom: 1px solid #E3E3E3;
}

.table-headers {
  margin-top: 2vmin;
  width: 100%;
  font-family: 'Exo 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 22px;
  /* identical to box height */


  /* Medium Gray */

  color: #8A8A8A;
}

.popup-box {
  width: v-bind(FixBoxWidth) !important;
}

@media (max-width: 680px) {
  .popup-box {
    width: 100% !important;
  }
}

.statistics-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5vmin;
}

.no-data-label {
  width: 100%;
  text-align: center;
  margin-top: 1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.4vmin;
  line-height: 4vmin;

  /* Gray Light */

  color: #E3E3E3;
}

.no-data-container {
  width: 100%;
  height: 60%;
}
</style>