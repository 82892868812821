<template>
  <div v-if="visible" :class="{'right-class': $i18n.locale === 'ar'}" class="button-container"
       :style="{'height': height,'width': width, 'padding-top': fullColor?'0vh':'calc(0.5*'+fontSize+'*(1vh + 1vw))',background: fullColor?background:''}">
    <button class="lbrx-button" @click="$emit('clicked')"
            :style="{'background':background, 'color':color, 'font-size': 'calc('+fontSize+'*(1vh + 1vw))', 'padding':(padding?padding:'1')+'px'}">
      <img v-if="icon" class="icon-style mt-1"
           :style="{'margin-right': $i18n.locale !== 'ar' ? '0.3vmin': '', 'margin-left': $i18n.locale === 'ar' ? '0.3vmin': '','height':'calc(1.3*'+fontSize+'*(1vh + 1vw))'}"
           :src="getIcon()"/> <span class="sub-label">{{ label }}</span>
    </button>
    <div v-if="modelValue != null" style="display:flex;" class="mx-1">
      <div v-for="device in modelValue" :key="device" class="status animate-flicker my-auto"
           :class="{'online': device.last_ping_status}"
           :style="{'margin-top': 'calc(0.7*'+fontSize+'*(1vh + 1vw))', height: 'calc(0.5*'+fontSize+'*(1vh + 1vw))', width: 'calc(0.5*'+fontSize+'*(1vh + 1vw))'}"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LbrxSubMenuButton',
  props: {
    fontSize: {
      required: true,
      value: String
    },
    height: {
      required: true,
      value: String
    },
    width: {
      required: true,
      value: String
    },
    background: {
      required: true,
      value: String
    },
    color: {
      required: true,
      value: String
    },
    label: {
      required: false,
      value: String
    },
    icon: {
      required: false,
      value: String
    },
    padding: {
      required: false,
      value: String
    },
    modelValue: Boolean,
    fullColor: Boolean,
    visible: {type: Boolean, default: true}
  },
  emits: ['clicked'],
  methods: {
    getIcon() {
      if (this.icon) {
        var images = require.context('../assets/')
        return images('./icons/' + this.icon + ".svg")
      }
    },
  }
}
</script>

<style scoped>
@media (max-width: 1200px) {
  .sub-label {
    display: none;
  }

  .icon-style {
    height: 3vmin !important;
    margin-top: auto !important;
  }

  .status {
    margin-top: 1.7vmin !important;
  }
}

.status {
  margin-left: 0.2vmin !important;
  margin-right: 0.2vmin !important;
}

.lbrx-button {
  border: none;
  font-family: 'Exo 2', serif;
  font-style: normal;
  /* identical to box height */
}

.icon-style {
  z-index: 99999;
  margin-top: auto;
}

.button-container {
  margin-bottom: 1vmin;
  margin-left: 0.5vmin;
  margin-right: 0.5vmin;
  display: flex;
  justify-content: center;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  color: red !important;
  font-weight: bold !important;
}
</style>