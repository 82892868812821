import {authHeader, ApiConfigs} from '../_helpers';
import store from '@/store'
import moment from "moment/moment";

export const businessService = {
    getCounterAgentStats,
    getBusinessInformations,
    BookingByRange,
    BookingById,
    createBooking,
    pushTicket,
    kiosksStatus,
    displaysStatus,
    refreshKiosk,
    refreshDisplay,
    refresh,
    getSlots,
    getDepServices,
    filterClients,
    createCustomer,
    getPaymentTypes,
    getNoteTypes,
    getDepartmentById,
    getTodayBookingSum,
    getCustomerComments,
    putBookingComment
};

function getDepartmentById(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.helpers.getDepById}`.replace(':id', id), requestOptions).then(handleResponse);
}

function getPaymentTypes() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.helpers.getPaymentTypes}`, requestOptions).then(handleResponse);
}

function getNoteTypes() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.helpers.getNoteTypes}`, requestOptions).then(handleResponse);
}

function putBookingComment(body: any, booking_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.bookings.putComment}`.replace(':id', booking_id), requestOptions).then(handleResponse);
}

function getCounterAgentStats() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.business.getCounterAgentStats}`, requestOptions).then(handleResponse);
}

function getTodayBookingSum() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({start: moment(new Date()).format('Y-M-D'), end: moment(new Date()).format('Y-M-D')})
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.business.getTodayBookingSum}`, requestOptions).then(handleResponse);
}

function getBusinessInformations() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.accounts.getBusinessInformations}`, requestOptions).then(handleResponse).then((res) => {
        return res
    });
}

function BookingByRange(start: string, end: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({start: start, end: end, teller: true})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.bookings.getByRange}`, requestOptions).then(handleResponse)
}

function BookingById(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.bookings.get.replace(':id', id)}`, requestOptions).then(handleResponse)
}

function kiosksStatus() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.business.getKiosksStatus}`, requestOptions).then(handleResponse)
}

function refreshKiosk(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.business.refreshKiosk.replace(':id', id)}`, requestOptions).then(handleResponse)
}

function refreshDisplay(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.business.refreshDisplay.replace(':id', id)}`, requestOptions).then(handleResponse)
}

function displaysStatus() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.business.getDisplaysStatus}`, requestOptions).then(handleResponse)
}

function refresh() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.business.refresh}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            user.user = response.account
            localStorage.setItem('user', JSON.stringify(user))
            return response
        });
}

function createBooking(body: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.bookings.create}`, requestOptions)
        .then(handleResponse);
}

function pushTicket(body: any, queue_id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.queues.push.replace(':id', queue_id)}`, requestOptions)
        .then(handleResponse);
}

function createCustomer(body: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.customers.create}`, requestOptions)
        .then(handleResponse);
}

function filterClients(keyword: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.customers.filter + keyword + '?page=1'}`, requestOptions)
        .then(handleResponse);
}

function getSlots(date: string, dep: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.bookings.getSlots.replace(':date', date).replace(':dep', dep)}`, requestOptions).then(handleResponse)
}

function getDepServices(dep: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.bookings.getDepServices.replace(':dep_id', dep)}`, requestOptions).then(handleResponse)
}

function getCustomerComments(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.customers.getComments.replace(':id', id)}`, requestOptions).then(handleResponse)
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                store.dispatch('authentication/logout')
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}