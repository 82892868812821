<template>
    <div class="button-container">
        <button class="lbrx-button" @click="$emit('clicked')" :style="{'background':background, 'color':color, 'height': height,'width': width, 'font-size': 'calc('+fontSize+'*(1vh + 1vw))', 'border': border ? '1px solid '+border : 'none'}">{{label}}
            <img v-if="icon" class="icon-style" :style="{'height':'calc('+fontSize+'*(1vh + 1vw))'}" :src="getIcon()"/>
        </button>
    </div>
</template>
<script>
export default {
    name: 'LbrxFooterButton',
    props: {
        border:{
            required: false,
            value: String
        },
        fontSize:{
            required: true,
            value: String
        },
        height:{
            required: true,
            value: String
        },
        width:{
            required: true,
            value: String
        },
        background:{
            required: true,
            value: String
        },
        color:{
            required: true,
            value: String
        },
        label:{
            required: false,
            value: String
        },
        icon:{
            required: false,
            value: String
        },
        modelValue:String,
    },
    emits: ['clicked'],
    methods: {
        getIcon() {
            if(this.icon) {
                var images = require.context('../assets/')
                return images('./icons/' + this.icon + ".svg")
            }
        },
    }
}
</script>

<style scoped>
.lbrx-button{
    font-family: 'Exo 2',serif;
    font-style: normal;
    line-height: 4vmin;
    /* identical to box height */
}
.icon-style{
    z-index: 99999;
    float: right;
    margin-top: 1vmin;
    margin-right: 0.3vmin;
}
.button-container {
    margin-bottom:1vmin;
    margin-left: 0.5vmin;
    margin-right: 0.5vmin;
    width: 100% !important;
    display: flex;
    justify-content: center;
}
</style>