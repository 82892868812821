<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box">
        <div :class="{'right-class': $i18n.locale === 'ar'}" class="container d-flex flex-column h-100">

          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon"
                     :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}"
                     src="../assets/icons/web-browser-icon.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('Teller.SessionConfig') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('Teller.SessionGuide') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>

          <div class="row">
            <div class="popup-body">
              <div class="popup-elements my-3">
                <div class="popup-title-text pop-up-body-title">
                  {{ $t('Teller.queue') }}
                </div>
                <div v-if="queues" style="margin-bottom: 5vmin" class="queues-list">
                      <span v-for="(queue, index) in queues" :key="index">
                      <lbrx-radio-button :id="'configQueue-'+queue.id" @select="checkQueue(queue)"
                                         :item="queue"></lbrx-radio-button>
                      </span>
                </div>
                <div class="popup-title-text pop-up-body-title" v-if="offices">
                  {{ $t('Teller.Counter') }}
                </div>
                <div v-if="offices" class="w-100 offices-list">
                  <div class="row w-100 justify-content-center">
                    <div class="col-auto single-office" v-for="(office, index) in offices" :key="index">
                      <lbrx-radio-button :id="'office-'+office.id" @select="checkOffice(office)"
                                         :item="office"></lbrx-radio-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="popup-buttons">
                <lbrx-button id="validateSession" v-model="sending" icon="tick-mark-icon" :base-button="true"
                             @clicked="triggerPopupAction()"
                             :label="$t('Teller.Open')"
                             color="#FFFFFF" background="#00CC6A" height="9vmin" width="93%"
                             font-size="1.1"></lbrx-button>
                <lbrx-button id="cancelSessionConfig" v-model="close" icon="delete-red-icon" :base-button="true"
                             @clicked=" closeCounter(); closeThis(true);"
                             :label="$t('Teller.Close')"
                             color="#FF5757" border="1px solid #FF5757" background="white" height="9vmin"
                             width="93%" font-size="1.1"></lbrx-button>

              </div>


            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxRadioButton from "@/components/LbrxRadioButton.vue";
import store from "@/store";
import {counterService} from "@/_services";

export default {
  name: 'LbrxSessionConfigurationPopup',
  inject: ['echo'],
  components: {LbrxRadioButton, LbrxButton},
  props: {
    modelValue: Boolean,
    queue_lines: Array,
  },
  data() {
    return {
      queues: [],
      offices: [],
      sending: true,
      close: false,
      showPopup: false
    }
  },
  methods: {
    triggerPopupAction() {
      //logic here
      let selected_counter = this.offices.filter((obj) => {
        return obj.selected === true;
      })
      this.$emit('unsubscribe');
      localStorage.setItem('counter', JSON.stringify(selected_counter.length === 1 ? selected_counter[0] : '{}'))
      this.closeThis(true);
    },
    closeThis(type = false) {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close', type);
      }, 700)
    },
    closeCounter() {
      let current_counter = JSON.parse(localStorage.getItem("counter") || "{}")
      counterService.close(current_counter.queue_id, current_counter.id).then((res) => {
        this.$emit('unsubscribe', current_counter.queue_id);
        localStorage.removeItem('counter')
        this.queues.forEach((queue) => queue.selected = false);
        this.offices.forEach((office) => office.selected = false);

      });

    },
    checkQueue(queue) {
      this.queues.forEach((queue) => queue.selected = false);
      this.offices = queue.counters
      queue.selected = true;
    },
    checkOffice(office) {
      this.offices.forEach((queue) => queue.selected = false);
      office.selected = true;
      this.sending = false
    },
  },
  mounted() {
    this.sending = false;
    this.queues = this.queue_lines
    this.offices = this.counters
    this.queues.forEach((queue) => {
      queue.selected = false
      queue.disabled = false
    });
    let current_counter = JSON.parse(localStorage.getItem("counter") || "{}")
    this.queues.forEach(queue => {
      if (queue.id === current_counter.queue_id) {
        queue.selected = true
        this.offices = queue.counters
        this.offices.forEach(counter => {
          if (counter.name === current_counter.name) {
            counter.selected = true
          }
        })
      }
    })
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  }
}
</script>

<style scoped>
.popup-elements {
  overflow-y: hidden;
}

.queues-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2vmin;
}

.offices-list {
  width: 100%;
  margin-top: 2vmin;
  padding: 0 !important;
  overflow-x: hidden;
}

.single-office {
  width: 24.9vmin;
  margin-bottom: 2vmin;
}

@media (max-width: 1200px) {
  .single-office {
    width: 30.5vmin;
  }
}

.popup-title-text {
  margin-bottom: 30px !important;
}
</style>